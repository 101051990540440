<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, computed } from "vue";
import { usePagesStore, Locales } from "../store";
import { watch } from "vue";
import { MqResponsive } from 'vue3-mq'
import { useRouter } from "vue-router";

const router = useRouter();


const { availableLocales, locale, t } = useI18n();
const store = usePagesStore();
const props = defineProps<{
  bgColor: string;
  logoText: string;
}>();

const emit = defineEmits<{
  (e: "changePage", page: string): void;
}>()

const langs = computed(
  () =>
    availableLocales.sort((a: string) =>
      a != locale.value ? 1 : -1
    ) as Locales[]
);

const barColor = computed(() => (props.bgColor == "white" ? "black" : "white"));

const menuOpened = ref<boolean>(window.innerWidth > 768 ? true : false);
const langPopped = ref<boolean>(false);
const overflowHidden = ref<boolean>(true);
const poplang = ref<HTMLElement | null>(null);
const loading = ref<boolean>(false);


const clickLang = (lang: Locales) => {
  locale.value = lang;
  store.locale = lang;
};

watch(menuOpened, (value) => {
  if (value) {
    setTimeout(() => {
      overflowHidden.value = false;
    }, 500);
  } else {
    overflowHidden.value = true;
  }
});

document.addEventListener('click', (ev) => {
  if(poplang.value && !poplang.value.contains(ev.target as Node)) langPopped.value = false
});

const redirectToTelegram = () => {
  loading.value = true;
  const linkUrl = 'https://t.me/UCU_media'
  const isMobile = window.innerWidth <= 768
  if (isMobile){
    window.location.href = linkUrl;
  }
  else {
    const newTab = window.open(linkUrl, '_blank'); 
    if (newTab) {
      newTab.focus();
    } 
    else {
      window.location.href = linkUrl;
    }  
  }
  loading.value = false;
}

const composeEmail = () => {
  loading.value = true;
  const email = 'email@e.mail'
  const isMobile = window.innerWidth <= 768
    if (isMobile){
      window.location.href = `mailto:${email}`;
    }
    else{
      window.open(`mailto:${email}`, "_blank");
    }
  loading.value = false;
}

const redirectToMain = () => {
  if (store.currentPageName == 'UCU'){
    router.push('/');
  } else{
    router.push('/rg')
  }
};


</script>


<template>
<div class="wrapper" :style="{ 'background': bgColor }">
  <div class="header" :class="{ 'overflow-hidden': overflowHidden }">
    <div class="logotype" @click="redirectToMain">
      {{ props.logoText }}
    </div>
    <div class="menu" @click.once="menuOpened = true">
      <transition name="stretch">
      <div class="box" v-if="menuOpened" :style="{'border-color': barColor, 'color': barColor,'background': bgColor }"> <!--  -->
        <div class="box-sections" :class="{'black': bgColor == 'black'}">
          <mq-responsive target="md+">
            <span v-if="store.currentPageName == 'UCU'" class="rg" @click="emit('changePage', 'RG')">{{ t('header.rosengarten') }}</span>
            <span v-else class="rg" @click="emit('changePage', 'UCU')">UCU</span>
          </mq-responsive>
          <div class="icon-container" :class="{'white-icons': bgColor == 'black' }">
            <img @click="redirectToTelegram"  src="/teleg.svg">
            <img @click="composeEmail"  src="/mail.svg">

            <!-- <a @click="redirectToTelegram" :target="isMobile ? '_self' : '_blank'" ></a>
            <a @click="composeEmail"       :target="isMobile ? '_self' : '_blank'" ></a> -->
          </div>
          <!-- <mq-responsive class="selected" target="md+">
            <div>Home</div>
          </mq-responsive>
          <mq-responsive target="md+">
            <div>About</div>
          </mq-responsive> -->
          <!-- <div class="contacts">
            <span>Contacts</span>
            <div class="popup-contacts">

            </div> 
          </div> -->
          <!-- <div v-if="store.currentPageName == 'UCU'" class="rg" @click="emit('changePage', 'RG')">Rosengarten</div>
          <div v-else class="rg" @click="emit('changePage', 'UCU')">UCU</div> --> 
        </div>
        <div class="box-languages" :class="{black: bgColor == 'black'}">
          <div class="languages" ref="poplang" @click="langPopped = !langPopped">
            <span>{{ locale != 'en' ? locale.toUpperCase(): (locale + 'g').toUpperCase() }}</span>
            <transition name="poplang">
              <div v-if="langPopped" class="popup" :class="{black: bgColor == 'black'}">
                <ul>
                  <li 
                      v-for="lang in langs"
                      @click="clickLang(lang)"
                      :key="lang"
                    >
                      {{ lang != 'en' ? lang.toUpperCase(): (lang + 'g').toUpperCase() }}
                    </li>
                </ul>
              </div>
            </transition>
            <div class="poplang">
              <mq-responsive target="md+">
                <img class="arrow-down" :class="{black: bgColor == 'black', popped: langPopped}" alt="down" src="../assets/arrow-down-black.svg" />
              </mq-responsive>
              <mq-responsive target="sm-">
                <img class="arrow-down" :class="{black: bgColor == 'black', popped: langPopped}" alt="down" src="../assets/arrow-down.svg" />
              </mq-responsive>
            </div>
          </div> 
        </div> 
      </div>
      </transition>
      <div
          class="burger-menu"
          :class="{ open: menuOpened }"
          @click="menuOpened = !menuOpened"
          :click="langPopped = false"
      >
        <div class="bar" :style="{ backgroundColor: barColor }"></div>
        <div class="bar" :style="{ backgroundColor: barColor }"></div>
      </div>
        <!-- </div>  -->
    </div>
    <!-- <mq-responsive class="contactus" target="md+">
      <span>CONTACT US</span>
    </mq-responsive> -->
  </div>
</div>
</template>

<style lang="scss" scoped>
// .header-visible {
//   opacity: 1;
//   transition: opacity 10s ease; /* Smooth transition effect with ease timing function */
//   position: sticky;
//   top: 0;
//   background-color: #333; /* Set your preferred background color */
//   color: #fff; /* Set your preferred text color */
//    /* Adjust the z-index to ensure the header is on top */
// }


// .header-visible:not(.header-visible) {
//   opacity: 0;
//   // pointer-events: none; /* Prevent interaction when hidden */
// }
.menu {
  display: flex;
  width: 100%;
  // height: 40px;
  z-index: 112;
}
.box {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  margin-right: 5px;

  @media screen  and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    border-radius: 20px;
    border: 4px solid #000;
    // background: #FFF;
  }

  @media screen and (max-width: 400px) {
    transform: scale(0.8) translateX(28px);
  }
}

.box-sections{
  display: flex;
  flex-direction: row;
  border: 2px solid;
  border-color: #000000;
  border-radius: 10px;
  height: 2rem;
  
  @media screen  and (min-width: 768px) {
    margin-left: 0;
    border: none;
    justify-content: space-between;
    width: 100%;
    // margin-left: auto; 
    // color: #000;
  }
  
  &>div{
    cursor: pointer;
    // color: #0f0f0f;
    font-family: "Roboto", Helvetica;
    font-size: 13px;
    font-weight: 500;
    align-self: center;
    padding: 16px;
    display: flex;
    gap: 16px;
    img:hover{
      transition: transform 0.3s ease;
      transform: scale(1.2);
    }
    // margin-left: 1em;
    @media screen  and (min-width: 768px) {
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 101.9%;
      padding: 9px 28px;
      
      border-radius: 14px;
      img {
        width: 32px;
      }
    }
    img {
      width: 30px;
    }
  }
  div.selected {
    background: #C00;
    color: #FCFBFB;
  }

  & :last-child{
    // margin-right: 2em;
    @media screen  and (min-width: 768px) {
      margin-right: 0;
    }
    // font-weight: 500;
  }
}

.box-sections.black {
  border-color: white;
}


.box-languages{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  border: 2px solid;
  border-color:#097ec1;
  border-radius: 10px;
  width: 5em;
  height: 32px;
  position: relative;
  z-index: 10;
  margin-right: 9px;

  @media screen  and (min-width: 768px) {
    border: none;
    margin: 10px;
    margin-right: 30px;
    .poplang {
      img {
        margin-left: 80px;
      }
    }
    // height: auto;
  }
}

.box-languages.black {
  border-color: white;
}

.languages{
  background-color: #097ec1;
  border-radius: 10px;
  height: 36px;
  width: 62px;
  top: -2px;
  left: -1em;
  color: #ffffff;
  font-family: "Montserrat-Bold", Helvetica;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20.4px;  
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    transition: transform 0.3s ease;
  }
  // &:hover {
  //   img {
  //     // trans
  //   }
  // }
  &>span{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 23px;
  }

  @media screen  and (min-width: 768px) {
    width: 70px;
    font-size: 19px;
  }

}

  .arrow-down{
    margin-top: 8px;
    margin-left: 70px;
    margin-right: 20px;
  }

  .arrow-down.black {
    filter: invert(100%);
  }

  .arrow-down.popped {
    transform: translateY(-2px) rotateX(180deg);
  } 

.popup {
  position: absolute;
  z-index: 35;
  right: -23px;
  top: 0px;
  border-radius: 10px;
  min-height: 52px;
  text-align: center;

  @media screen  and (min-width: 768px) {
    right: -19px;
  }
  
  ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
    padding-right: 23px;
    padding-left: 23px;
    text-align: center;
     & :first-child{
        background-color: #097ec1;
      }
    li {
      display: flex;
      margin-bottom: 4px;
      cursor: pointer;
      border-radius: 0.625rem;
      background: #82898C;
      width: 3.875rem;
      height: 36px;
      align-items: center;
      justify-content: center;
      letter-spacing: 0;
      line-height: 20.4px;
     
      
      // background-color: black;
      // color: white;
      // border-radius: 5px;
      // padding: 2px 4px;
      // text-align: center;
    }
  }
}
// .box .group {
//   border: 2px solid;
//   border-color: #097ec1;
//   border-radius: 10px;
//   height: 40px;
 
//   width: 102px;
// }

// .box .overlap-group-wrapper {
//   height: 40px;
//   left: -2px;
//   position: absolute;
//   top: -2px;
//   width: 64px;
// }

// .box .overlap-group {
//   background-color: #097ec1;
//   border-radius: 10px;
//   height: 40px;
//   position: relative;
//   width: 62px;
// }

// .box .text-wrapper {
//   color: #ffffff;
//   font-family: "Montserrat-Bold", Helvetica;
//   font-size: 20px;
//   font-weight: 700;
//   left: 17px;
//   letter-spacing: 0;
//   line-height: 20.4px;
//   position: absolute;
//   top: 10px;
//   white-space: nowrap;
// }

// .box .vector {
//   height: 11px;
//   left: 68px;
//   position: absolute;
//   top: 13px;
//   width: 19px;
// }

.burger-menu {
  margin-left: auto;
  z-index: 10;
  box-sizing: border-box;
  width: 27px;
  height: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 10px;

  @media screen  and (min-width: 768px) {
    display: none;
  }
  
}

.burger-menu.open .bar:nth-child(1) {
  transform: rotate(-47deg) translate(-4px, 4.5px);
  // width: 110%;
}

.burger-menu.open .bar:nth-child(2) {
  transform: rotate(47deg) translate(-5px, -5px);
  // width: 110%;
}

.bar {
  width: 30px;
  height: 2.7px;
  transition: all 0.3s ease;
}

.root {
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.wrapper {
  width: 100%;
  height: 5em;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  margin-left: -20px;
  margin-bottom: 50px;
  // padding: 15px 30px 0px 0px;
  // padding-left: 30px;
  // margin-left: -20px;
  // margin-bottom: 60px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1); 
  // position: sticky;
  // top: 0;
  z-index: 19;
  // background-color: #fff;
  @media screen and (max-width: 768px)  {
    height: 3em;
    margin-bottom: 30px;
    padding-bottom: 8px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  align-content: space-around;
  height: 60px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  z-index: 20;
  margin-top: 10px;
  
  // min-width: 320px;
  // padding-top: 25px;
  @media screen  and (min-width: 768px) {
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 30px;
    width: 95%;
  }
}

.header.overflow-hidden {
  @media screen and (max-width: 768px) {
    overflow: hidden;
  }
}

.logotype {
  display: flex;
  font-size: 32px;
  font-weight: 700;
  justify-content: start;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    margin-right: 32px;
    font-weight: 900;
    font-size: 40px;
  }
}

.icon-container{

}

// .menu {
//   border-radius: 10px;
//   display: flex;
//   flex-direction: row;
//   align-items: stretch;
//   justify-content: end;
//   justify-items: end;
// }
// .menu {
//   height: 60px;
//   border: 0px solid black;
//   border-radius: 10px;
//   gap: 1px;
// }

// .openMenu {
//   background: url(/openMenu.svg);
//   background-repeat: no-repeat;
//   background-position: center 76%;
//   background-size:  350px auto;
//   font-family: Roboto, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 124.5%;
//   border-radius: 10px;
//   transition: transform 0.3s ease;
//   transform: translateX(10px);
// }

// .openMenu.black {
//   background: url(/openRGMenu.svg);
//     span, a{
//       color: white !important;
//     }
// }

.stretch-enter-active,
.stretch-leave-active {
  
}


.stretch-enter-to,
.stretch-leave-from {
  transition: transform 0.5s, opacity 0.4s;
}

.stretch-enter-from,
.stretch-leave-to {
  transform: translateX(100px);
  transition: all 0.5s ease;
  opacity: 0;
}

.openMenuList {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  justify-content: left;
  align-content: center;
  gap: 10px;
  padding: 0px;
  margin-top: 0;
  margin-bottom: 0;
  span {
    margin-left: 23px;
  }
}

.openMenuList > div {
  color: #000000;
}

.contacts {
  a {
    color: #000000;
    text-decoration: none;
  }
  position: relative;
  // text-decoration: underline;
}

.popup-contact {
  position: absolute;
  color: black;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 124.5%;
  z-index: 40;
  left: -10px;
  bottom: -40px;
  // padding: 0px 2px 0px 2px;
  background-color: #f6f6f6;
  border-radius: 10px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .language {
//   color: #020202;
//   display: flex;
//   justify-content: start;
//   align-content: start;
//   align-items: start;
//   justify-items: start;
//   position: relative;
//   bottom: 0;
//   // text-decoration: underline;
//   margin-left: 0px;
//   width: 45px;
// }
.faq {
  color: #231e1e;
}


// .popup.black {
//   background-color: #5c5c5c;
//   color: white;
// }

.poplang-enter-from,
.poplang-leave-to {
  opacity: 0;
}

.poplang-enter-to,
.poplang-leave-from {
  opacity: 1;
}

.poplang-enter-active,
.poplang-leave-active {
  transition: opacity 0.3s ease;
}

.contactus {
  margin-left: 16px;
  border-radius: 20px;
  background: #C00;
  color: #FFF;
  font-family: Roboto Mono;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  box-sizing: border-box;
  width: 233px;
  text-align: center;
  padding: 20px 0;
  // span {
  //   margin: 16px 45px;
  // }
}

.white-icons {
  img {
    filter: invert(100%);
  }
}
</style>