<script setup lang="ts">

import { ArticleProps } from "../store";
import { useRouter } from "vue-router" ;
const router = useRouter()
import MarkdownIt from 'markdown-it'

const props = defineProps<{
  articles: ArticleProps[];
  articleType: string;
  bgColor: string;
}>();

const markdown = new MarkdownIt()


const getCaption = (content: string) => {
  return /<p>([\s\S]*)<\/p>/.exec(markdown.render(content))
}

const clickButton = (articleId?: number) => {
  const articleUrl = props.articleType == 'ucu' ? 'article' : 'articleRg'
  router.push(`/${articleUrl}/${articleId}`)
}

</script>


<template>
  <div class="article-wrapper">
    <div class="article"  v-for="(article, index) in articles" :key="index" @click="clickButton(article.id)">
      <div class="grey-wrap"></div>
      <div class="title-text">{{ article.titleText }}</div>
      <div class="main-text-caption">{{ getCaption(article.content) }}</div>
      <img :src="article.imageSource" alt="Article Image" rel="preload" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.article {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  width: 100%;
  cursor: pointer;
  .grey-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: linear-gradient(180deg, rgba(196, 196, 196, 0.00) 27.6%, rgba(119, 120, 120, 0.42) 51.56%, #404142 100%);
  }
  img {
    flex-shrink: 0;
    margin: 1em 0em 0em;
    border: 2px solid #999999;
    border-radius: 17px;
    width: 100%;
    height: 250px;
    object-fit: cover;
    max-width: 100%;
    display: block;
    // opacity: 0.6;
    display: block;
  }
}

.article-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 25px;
}

.title {
  font-size: 16px;
  line-height: 120%;
  display: flex;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  letter-spacing: 1%;
  text-align: start;
  font-weight: 800;
}

.title-and-content {
  display: flex;
  flex-direction: column;
  text-align: start;
  flex-grow: 1;
}

.articleBlockTop {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.content {
  font-family: Roboto, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 2%;
  text-align: start;
  line-height: 120%;
  font-weight: 400;
  margin: 0;
  margin-bottom: 23px; 
}

.author {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  line-height: 120%;
  margin-bottom: 15px;
}

.button {
  border-radius: 15px;
  text-align: start;
  height: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
  transition: transform 0.2s, box-shadow 0.2s;

}

.button:hover {
  cursor: pointer;
  transform: translateY(-3px); /* Move the button 3 pixels up */
  box-shadow: 0 2px 4px rgba(209, 44, 44, 0.712); /* Apply a box shadow */
  cursor: pointer;
}

.button:active{
  color: white;
  background-color: black;
}


.button-black {
  border: 1px solid black;
}

.button-white {
  color: black;
  background-color: white;
}

.button-text {
  font-size: 13px;
  color: #000000;
  line-height: 124.5%;
  text-decoration: none;
}


.article:nth-child(even) {
  flex-direction: row-reverse;
  .article-box {
    margin-left: 0px;
    margin-right: 21px;
  }
  .author-and-button {
    margin-left: 0px;
  }
  .title-and-content{
    margin-left: 0px;
  }
  .button{
    width: 100%;
  }
}

.article:hover {
  .title-text{
    bottom: 5.3em;
    padding-bottom: 2px;
  }
  .main-text-caption {
    bottom: 10px;
    opacity: 1;
  }
}

.title-text {
  padding-bottom: 20px;
  z-index: 2;
  position: absolute;
  bottom: 0em;
  left: 1em;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  letter-spacing: 0.02rem;
  text-align: left;
  transition: all 0.5s ease, opacity 0.5s ease;
}

.main-text-caption {
  position: absolute;
  bottom: -50px; 
  left: 0.5em;
  width: 90%;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; 
  letter-spacing: 0.025rem;
  text-align: center;
  padding: 10px;    
  opacity: 0; 
  transition: bottom 0.5s ease, opacity 0.5s ease; 
  z-index: 10;
  text-align: left;
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: 4 * (15px); /* Adjust the value as needed */
  text-overflow: ellipsis;
  overflow: hidden;
}
.main-text-caption:hover{
  display: block;
  position: absolute;
  
}
</style>