<script lang="ts" setup>
import  Cover  from './Cover.vue';
import  Articles  from "./Articles.vue"; 
// import  Meetings  from './Meetings.vue';
import  ArticlesPopular from './ArticlesPopular.vue';
import NewsArticles from './NewsArticles.vue';
import { MqResponsive } from 'vue3-mq'

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { usePagesStore } from '../store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const router = useRouter();

const props = defineProps<{
  currentPageName: string
}>()

const { t } = useI18n();


const store = usePagesStore();
const currentComponent = ref<string>(props.currentPageName)
const buttonState = ref<string>("expand")
const animDirection = ref<string>("left")

const key = ref(0)
const coverHeight = ref<any>(0)

const handleNextPage = () => {
  buttonState.value = "expand"
  if (props.currentPageName == "UCU") router.push("/rg")
  else router.push("/")
}

const changeComponent = () => {
  if(currentComponent.value === 'UCU') {
    currentComponent.value = 'RG'
    if(props.currentPageName == 'UCU') buttonState.value = "next"
    else buttonState.value = "expand"
  
  } else {
    currentComponent.value = 'UCU'
    if(props.currentPageName == 'RG') buttonState.value = "next"
    else buttonState.value = "expand"
  }
}

onMounted(() => {
  const cover = document.querySelector('div.cover.selected')

  const observer = new ResizeObserver((entry) => {
    coverHeight.value = entry[0].contentRect.height
  })

  if(cover) observer.observe(cover)
})


setTimeout(() => {
  coverHeight.value = document.querySelector('div.cover.selected')?.clientHeight
}, 1000)


const handleSwipe = (direction: string) => {
  animDirection.value = direction
  changeComponent()
}

</script>

<template>
  <div :key="key">
    <!-- <MisPage /> -->
    <MqResponsive target="sm-">
        <transition :name="'fade-' + animDirection" mode="out-in">
        <Cover 
          v-if="currentComponent == 'UCU'"
          :current-component="currentComponent"
          :button-state="buttonState"
          :about-props="store.ucuAbout"
          :icon="'/ucu-vector.svg'"
          @next-page="handleNextPage"
          @next-component="handleSwipe"
        />
        <Cover 
          v-else
          :current-component="currentComponent"
          :button-state="buttonState"
          :about-props="store.rgAbout"
          :icon="'/R.svg'"
          @next-page="handleNextPage"
          @next-component="handleSwipe"
        /> 
        </transition>
        <transition name="swipe-fade" mode="out-in">
        <div class="swipe">
          <div class="swipeBlock">
            <div class="swipeBlockUnit" :class="{black: currentComponent == 'UCU'}" @click="changeComponent" />
            <div class="swipeBlockUnit" :class="{black: currentComponent == 'RG'}" @click="changeComponent" />
          </div>
        </div>
        </transition>
    </MqResponsive>
    <div class="desktop-wrapper">
      <MqResponsive target="md+" class="left-side">
        <div class="desktop-cover">
          <Cover
            :class="{'selected': currentComponent == 'UCU', 'unselected': currentComponent == 'RG'}"
            :current-component="currentComponent"
            :button-state="buttonState"
            :about-props="store.ucuAbout"
            :disabled="currentComponent !== 'UCU'"
            :icon="'/ucu-vector.svg'"
            @next-page="handleNextPage"
            @next-component="handleSwipe"
            @open-about="router.push('/about')"
            @click="currentComponent == 'RG' ? changeComponent() : null"
          />
          <Cover 
            :class="{'selected': currentComponent == 'RG', 'unselected': currentComponent == 'UCU'}"
            :style="{ border: '3px solid #5C5C5C'}"
            :current-component="currentComponent"
            :button-state="buttonState"
            :about-props="store.rgAbout"
            :disabled="currentComponent !== 'RG'"
            :icon="'/R.svg'"
            @next-page="handleNextPage"
            @next-component="handleSwipe"
            @open-about="router.push('/rg/about')"
            @click="currentComponent == 'UCU' ? changeComponent() : null"
          /> 
        </div>
      </MqResponsive>
      <div v-if="currentPageName === 'UCU'" class="right-side">
        <MqResponsive target="md+"><h1>{{ t('root.mostPopular') }}</h1></MqResponsive>
        <ArticlesPopular 
          :display-height="coverHeight" 
          :article1="store.popularArticles[0]"
          :article2="store.popularArticles[1]"
        />
      </div>
      <div v-else class="right-side">
        <!-- <Meetings :display-height="coverHeight" :program1="store.rgProgram(1)" :program2="store.rgProgram(2)" /> -->
      </div>
    </div>
    <div v-if="currentPageName === 'UCU'">
      <Articles :articles="store.articles" article-type="ucu" bg-color="white" />
    </div>
    <div v-if="currentPageName === 'UCU'">
        <h1 class="news">{{ t('root.newsFromUkraine') }}</h1>
        <NewsArticles :news="store.news" />
    </div>
    <div v-else class="sider">
      <!-- <Articles :articles="store.rgArticles" article-type="rg" bg-color="black" />  -->
    </div>
  </div> 
</template>

<style scoped lang="scss">
.App {
  margin: 0;
  text-align: center;
  background-color: #fbfbfb;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  width: 100%;
  min-width: 320px;
  
}

@keyframes fadeEnterLeft {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeEnterRight {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

.fade-left-enter-active {
  animation: fadeEnterLeft 0.2s ease;
}

.fade-right-enter-active {
  animation: fadeEnterRight 0.2s ease;
}

.fade-right-leave-active {
  animation: fadeOutRight 0.3s ease backwards;
}

.fade-left-leave-active {
  animation: fadeOutLeft 0.3s ease backwards;
}

.logo {
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

.logo.ts:hover {
  filter: drop-shadow(0 0 2em #3178c6aa);
}

.logo.scss:hover {
  filter: drop-shadow(0 0 2em #cc6698aa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.firstpart {
  color: #cb4747;
}


.main {
  display: flex;
  align-self: center;
  margin-top: 31px;
  line-height: auto;
  flex-direction: column;
}


.button {
  border: 2px solid #999999;
  margin: 70px 25px 25px;
  border-radius: 34.5px;
  width: 35em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
}
.swipeButton {
  margin-top: 25px;
  margin-bottom: 25px;
}
.body {
  display: flex;
  flex-direction: column;
}
.header{
  width: 100%;
  min-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipe {
  height: 14px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.swipeBlock {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  justify-content: center;
}
.swipeBlockUnit {
  width: 14px;
  height: 14px;
  background-color: white;
  border: 1px solid black;
  border-radius: 99px;
  margin: 0px 1px;
  height: 9px;
  width: 9px;
  margin-right: 2px;
  margin-left: 2px;
  border: 2px solid black;
}

.swipeBlockUnit.black {
  background-color: #ffd700;
}

.swipe-fade-enter-active, .swipe-fade-leave-active {
  transition: opacity 2s ease;
}
.swipe-fade-enter, .swipe-fade-leave-to {
  opacity: 0;
}

.desktop-cover {
  display: flex;
  justify-content: left;
  gap: 40px;
  width: 100%;
  margin: 0;
  position: relative;
  padding: 40px 0;
  div {
    width: 65%;
    position: absolute;
    right: 180px;
    background: white;
    max-height: 700px;
    transition: all 0.3s ease-out;
    top: 0;
    left: 0;

    @media screen and (max-width: 1300px) {
      width: 80%;
    }
  }

  div.selected {
    z-index: 10;
    box-shadow: -7px 13px 20px 0px rgba(0, 0, 0, 0.20);
  }

  div.unselected {
    transform: translate(180px, 15px);
    opacity: 0.4;
    cursor: pointer;
    @media screen and (max-width: 1500px) {
      transform: translate(130px, 15px);
    }
    @media screen and (max-width: 1300px) {
      transform: translate(40px, 15px);
    }
  }
}

h1.news {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  margin-top: 60px;
}

@media screen and (min-width: 768px) {

  .desktop-wrapper {
    display: flex;
    gap: 50px;
    justify-content: center;

    @media screen and (max-width: 1100px) {
      gap: 40px;
    }
  }

  .left-side {
    width: 50%;
    padding-left: 80px;
    @media screen and (max-width: 1500px) {
     padding-left: 30px; 
    }
    @media screen and (max-width: 1050px) {
      width: 60%;
    }
    @media screen and (max-width: 850px) {
      width: 70%;
    }
  }

  h1 {
      font-family: Inter;
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 124.523%; /* 49.809px */
      letter-spacing: 0.4px;
      margin: 0;
      margin-bottom: 20px;
    }

  .right-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 80px;
    @media screen and (max-width: 1500px) {
     padding-right: 30px; 
    }
    @media screen and (max-width: 1050px) {
      width: 40%;
    }
    @media screen and (max-width: 1050px) {
      width: 40%;
    }
    @media screen and (max-width: 850px) {
      width: 30%;
    }
    
    div {
      width: 100%
    }

  }
}
</style>