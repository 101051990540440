const messages = {
  en: {
    root: {
      mostPopular: 'Most popular',
      newsFromUkraine: 'News from Ukraine'
    },
    header: {
      rosengarten: 'Rosengarten',
    },
    cover: {
      buttonRead: 'ABOUT',
      buttonAbout: 'SWITCH',
      buttonClose: 'CLOSE',
    },
    misButton: {
      title: "Marxism and Modernity",
      subtitle: "Scientific political journal"
    },
    sendForm:{
      step_0:{
        titleText: 'Greetings!',
        nicknameText: 'Nickname',
        placeholderNicknameText: 'Your nickname',
        nicknameErrorText: 'Nickname is required',
        emailText:'Enter email',
        placeholderEmailText: 'Use secure email',
        tgUsernameText: 'Enter telegram username',
        countryLocationText: "Country of location",
        placeholderCountryLocationText: 'Germany',
      },
      step_1:{
        belongsReasonText: 'Belonging to the Communist Party or other communist organisation',
        buttonYes: 'Yes',
        buttonNo: 'No',
        supportReasonText: 'Your attitude towards the Vain War. Do you support either side?',
        russianSideText: 'Support Russia',
        ukrainianSideText:'Support Ukraine',
        neitherSideText:'Neither side',
        otherSideText:'Other answer',
      },
      step_2:{
        acquaintedProgrammesText:'What programmes of the RosenGarten did you get acquainted with?',
        wantProgrammesText: 'Which programmes would you prefer to participate in?',
        imperialismProgrammesText: 'About imperialism and war',
        opportunismProgrammesText: 'About opportunism and revisionism',
      }, 
      step_3:{
        daysOfWeekText: 'Which days of the week are most convenient for you to join the Rosengarten meetings',
        MonText: 'Mon',
        TueText: 'Tue',
        WedText: 'Wed',
        ThuText: 'Thu',
        FriText: 'Fri',
        SatText: 'Sat',
        SunText: 'Sun',
        timeIntervalsText: 'Please select the time intervals that you are available for connection',
        hoursForReadingText: 'How many hours a week are you willing to spend to reading literature?',
        hoursForOnlineText: 'How many hours a week are you available to be part of an online Rosengarten?',
        wishesText:'Describe your wishes regarding the creation of new learning programs, propose topics of your interest:',
        buttonSendText: 'Send application',
      },
      step_4:{
        successSendText: 'Your application was successfully sended',
      },
      error: {
        nicknameRequired: 'Enter your nickname',
        nicknameLength: 'Nickname must be not less than 3 characters',
        emailRequired: 'Email is required',
        emailType: "Email must contain {'@'} and domain name",
        usernameRequired: 'Enter your telegram username',
        usernameStartsWith: "Telegram username must start with {'@'}",
        countryLocationRequired: 'Country of location is required',
        fieldReasonRequired: 'Give a reason',
        answerRequired: 'Give an answer',
      },

      buttonNextText: 'Next',
      detailedText: 'Give a detailed answer',    
      helpText: '(you can choose more than one answer)',
      placeholderReasonText: 'Give reason...',
      placeholderAnswerText: 'Your answer...',
    },
    select:{
      selectUtcText: 'Select your UTC',
    }

  },

  ru: {
    root: {
      mostPopular: 'Самое популярное',
      newsFromUkraine: 'Новости из Украины'
    },
    header: {
      rosengarten: 'Розенгартен'
    },
    cover: {
      buttonRead: 'О НАС',
      buttonAbout: 'ДАЛЕЕ', 
      buttonClose: 'ЗАКРЫТЬ',
    },
    sendForm:{
      step_0:{
        titleText: 'Приветствуем!',
        nicknameText: 'Псевдоним',
        placeholderNicknameText: 'Ваш псевдоним',
        nicknameErrorText: 'Псевдоним обязателен',
        emailText:'Введите e-mail',
        placeholderEmailText: 'Используйте безопасный email',
        tgUsernameText: 'Введите имя пользователя telegram',
        countryLocationText: "Страна нахождения",
        placeholderCountryLocationText: 'Германия',
      },
      step_1:{
        belongsReasonText: 'Принадлежность к Коммунистическому Партии или другим коммунистическим организациям',
        buttonYes: 'Да',
        buttonNo: 'Нет',
        supportReasonText: 'Ваше отношение к Напрасной Войне. Какую из сторон Вы поддерживаете?',
        russianSideText: 'Поддерживаю Россию',
        ukrainianSideText:'Поддерживаю Украину',
        neitherSideText:'Ни одну из сторон',
        otherSideText:'Другой ответ',
      },
      step_2:{
        acquaintedProgrammesText:'С какими программами RosenGarten Вы уже ознакомились?',
        wantProgrammesText: 'В каких программах хотите принять участие?',
        imperialismProgrammesText: 'Об империализме и войне',
        opportunismProgrammesText: 'Об оппортунизме и ревизионизме',
      },
      step_3:{
        daysOfWeekText: 'Какие дни недели Вам наиболее удобны для подключения ко встречам RosenGarten',
        MonText: 'Пн',
        TueText: 'Вт',
        WedText: 'Ср',
        ThuText: 'Чт',
        FriText: 'Пт',
        SatText: 'Сб',
        SunText: 'Вс',
        timeIntervalsText: 'Пожалуйста, выберите временные промежутки для подключения',
        hoursForReadingText: 'Сколько часов в неделю Вы готовы уделять чтению литературы?',
        hoursForOnlineText: 'Сколько часов в неделю Вы готовы участвовать в онлайн кружках?',
        wishesText: 'Напишите Ваше пожелание для создания новых учебных программ, предложите темы интересующие Вас:',
        buttonSendText: 'Отправить заявку',
      },
      step_4:{
        successSendText: 'Ваша заявка была успешно отправлена',
      },
      error: {
        nicknameRequired: 'Введите Ваш псевдоним',
        nicknameLength: 'Псевдоним должен содержать не менее 3 символов',
        emailRequired: 'Введите Ваш email',
        emailType: "Почта должна содержать {'@'} и доменное имя",
        usernameRequired: 'Введите имя пользователя telegram',
        usernameStartsWith: "Имя пользователя telegram должно начинаться с {'@'}",
        countryLocationRequired: 'Введите страну нахождения',
        fieldReasonRequired: 'Опишите подробнее',
        answerRequired: 'Введите Ваш ответ',
      },

      buttonNextText: 'Далее',
      detailedText: 'Дайте развернутый ответ',
      helpText: '(Вы можете выбрать более одного ответа)',
      placeholderReasonText: 'Опишите подробнее...',
      placeholderAnswerText: 'Ваш ответ...',
    },
    select:{
      selectUtcText: 'Выберите Ваш UTC',
    }
  },

  uk:{
    root: {
      mostPopular: 'Найпопулярніше',
      newsFromUkraine: 'Новини з України'
    },
    header: {
      rosengarten: 'Розенгартен'
    },
    cover: {
      buttonRead: 'ПРО НАС',
      buttonAbout: 'ДАЛІ',  
      buttonClose: 'ЗАКРИТИ'
      
    },
    sendForm:{
      step_0:{
        titleText: 'Вітаємо!',
        nicknameText: 'Псевдонім',
        placeholderNicknameText: 'Ваш псевдонім',
        nicknameErrorText: 'Псевдонім обязателен',
        emailText:'Введіть e-mail',
        placeholderEmailText: 'Використовуйте безпечний email',
        tgUsernameText: `Введіть ім\'я користувача telegram`,
        countryLocationText: "Країна знаходження",
        placeholderCountryLocationText: 'Германія',
      },
      step_1:{
        belongsReasonText: 'Приналежність до Коммуністичної Партії чи інших коммуністичних організацій',
        buttonYes: 'Так',
        buttonNo: 'Ні',
        supportReasonText: 'Ваше відношення до Марної Війни. Яку зі сторін Ви підтримуєте?',
        russianSideText: 'Підтримую Росію',
        ukrainianSideText:'Підтримую Україну',
        neitherSideText:'Жодну зі сторін',
        otherSideText:'Інша відповідь',
      },
      step_2:{
        acquaintedProgrammesText:'З якими програмами RosenGarten Ви вже ознайомились?',
        wantProgrammesText: 'В яких програмах маєте намір взяти участь?',
        imperialismProgrammesText: 'Про імперіалізм і війну',
        opportunismProgrammesText: 'Про опортунізм і ревізіонізм',
      },
      step_3:{
        daysOfWeekText: 'Які дні тижня Вам найбільш зручні для підключення до зустрічей RosenGarten',
        MonText: 'Пн',
        TueText: 'Вт',
        WedText: 'Ср',
        ThuText: 'Чт',
        FriText: 'Пт',
        SatText: 'Сб',
        SunText: 'Нд',
        timeIntervalsText: 'Будь ласка, оберіть часові інтервали, в які Вам зручно підключатися',
        hoursForReadingText: 'Скільки годин на тиждень Ви готові приділяти читанню літератури?',
        hoursForOnlineText: 'Скільки годин на тиждень Ви готові долучатись до онлайн гуртків?',
        wishesText: 'Напишіть Ваше побажання щодо створення нових навчальних програм, запропонуйте теми, що цікавлять Вас:',
        buttonSendText: 'Надіслати заявку',
      },
      step_4:{
        successSendText: 'Ваша заявка була успішно відправлена',
      },
      error: {
        nicknameRequired: 'Введіть Ваш псевдонім',
        nicknameLength: 'Псевдонім має містити щонайменше 3 символи',
        emailRequired: 'Введіть Ваш email',
        emailType: "Пошта повинна містити {'@'} і доменне ім\'я",
        usernameRequired: 'Введіть Ваше ім\'я користувача telegram ',
        usernameStartsWith: "Ім\'я користувача telegram має починатися із {'@'}",
        countryLocationRequired: 'Введіть країну знаходження',
        fieldReasonRequired: 'Опишіть детальніше',
        answerRequired: 'Введіть Вашу відповідь',
      },

      buttonNextText: 'Далі',
      detailedText: 'Дайте розгорнуту відповідь',
      helpText: '(Ви можете вибрати більше однієї відповіді)',
      placeholderReasonText: 'Опишіть детальніше...',
      placeholderAnswerText: 'Ваша відповідь...',
    },
    select:{
      selectUtcText: 'Оберіть Ваш UTC',
    }
  }
}

export default messages