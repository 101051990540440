<script lang="ts" setup>
import { ArticleProps } from '../store';
import { useRouter } from 'vue-router';
import MarkdownIt from 'markdown-it'

const router = useRouter();
defineProps<{
  displayHeight: number;
  article1: ArticleProps,
  article2: ArticleProps,
}>()

const markdown = new MarkdownIt()

const getCaption = (content: string) => {
  const modifiedContent = markdown.render(content).replace(/<[^>]*>/gm, '');
  return modifiedContent
}
</script>

<template>
  <div class="root" :style="{'--display-height-up': (displayHeight - 60)* 0.6 + 'px', '--display-height-down': (displayHeight - 60) * 0.4 + 'px'}">
    <div class="wrapper">
      <!-- <div class="main" @click="router.push(`/mis`)">
        <div class="main-text">Marksism i sovremennost</div>
        <div class="grey-wrap"></div> 
        <img class="main-img" :src="article1.imageSource" alt="">
        <div class="main-text-caption"> Nauchno politicheskiy zhurnal</div>
      </div> -->
      <div class="main" @click="router.push(`/popular-article/${article1.id}`)">
        <div class="side-text">{{ article1.titleText }}</div>
        <div class="grey-wrap"></div>
        <div class="text-caption">{{ getCaption(article1.content) }}</div>
        <img class="up-img" :src="article1.imageSource" alt="">
      </div>
      <div v-if="article2" class="side-up" @click="router.push(`/popular-article/${article2.id}`)">
        <div class="side-text">{{ article2.titleText }}</div>
        <div class="grey-wrap"></div>
        <div class="text-caption">{{ getCaption(article2.content) }}</div>
        <img class="down-img" :src="article2.imageSource" alt="">
      </div>
      <!-- <div v-if="article1" class="side-down" @click="router.push(`/popular-article/${article2.id}`)">
        <div class="side-text">{{ article2.titleText }}</div>
        <div class="grey-wrap"></div>
        <div class="text-caption">{{ getCaption(article2.content) }}</div>
        <img class="down-img" :src="article2.imageSource" alt="">
      </div> -->
    </div>
  </div>
</template>


<style lang="scss" scoped>
.wrapper{
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: repeat(2, 150px);

  grid-column-gap: 5px;
  grid-row-gap: 5px; 
  grid-auto-rows: 50px;

  @media screen and (min-width: 768px) {
    $default-height: 690px; 
    grid-template-rows: var(--display-height-up, $default-height * 0.57)  var(--display-height-down, $default-height * 0.43);
    grid-template-columns: 50% 50%;
    row-gap: 20px;
    column-gap: 20px;
  }

  @media screen and (max-width: 450px) {
    grid-template-rows: repeat(2, 120px);
  }
}

img{
  // opacity: 0.6;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  

  @media screen and (min-width: 768px) {
    border-radius: 15px;
  }
}

.main-img{
  transition: opacity 0.5s ease;
  border-radius: 0.9375rem;
  // object-fit: fill;
}

.main, .side-up, .side-down{
  position: relative;
  border-radius: 0.5em;
  // width: 100%;
  // background: linear-gradient(180deg, rgba(196, 196, 196, 0.00) 27.6%, rgba(119, 120, 120, 0.8) 51.56%, #404142 100%);
  cursor: pointer;
}

.wrapper.less-then-3 .main {
  @media screen and (max-width: 768px) {
    grid-area: 1 / 1 / 3 / 3;
  }
}

.main {
  width: 100%;  
  height: 100%;
  grid-area: 1 / 1 / 3 / 2;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    grid-area: 1 / 1 / 2 / 3;
  }
  .main-text {
    font-size: 16px !important;
    @media screen and (min-width: 768px) {
      font-size: 24px !important;
    }
    @media screen and (min-width: 1600px) {
      font-size: 30px !important;
    }
  }

}

.side-up {
  height: 100%;
  @media screen and (min-width: 768px) {
    grid-area: 2 / 1 / 3 / 2;
  }
  @media screen and (max-width: 1050px) {
    grid-area: 2 / 1 / 3 / 3;
  }
  @media screen and (max-width: 768px) {
    grid-area: 1 / 2 / 2 / 3;
  }
  overflow: hidden;
}

.side-down {
  height: 100%;
  @media screen and (min-width: 768px) {
    grid-area: 2 / 2 / 3 / 3;
  }

  @media screen and (max-width: 1050px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
  grid-area: 2 / 2 / 3 / 3;
  overflow: hidden;
}




@media screen and (min-width: 768px) {
  .main:hover, .side-up:hover, .side-down:hover {
    .main-text {
      bottom: 6.2em;
      // left: 20px;
      padding-bottom: 2px;
      @media screen and (min-width: 1600px) {
        bottom: 5.3em;
      }
    }
    .side-text {
      bottom: 7.5em;
      padding-bottom: 2px;
      @media screen and (min-width: 1600px) {
        bottom: 5.3em;
      }
    }

    .main-text-caption {
      bottom: 20px;
      opacity: 1;
    }

    .text-caption {
      bottom: 20px;
      opacity: 1;
    }
  } 
}

.side-text{
  width: 80%;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .side-down-text{
//   width: 80%;
//   height: auto;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.main-text, .side-text {
  padding-bottom: 20px;
  z-index: 2;
  position: absolute;
  bottom: 0em;
  left: 20px;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  letter-spacing: 0.02rem;
  text-align: left;
  transition: bottom 0.5s ease, opacity 0.5s ease;

  @media screen and (min-width: 1600px) {
    font-size: 30px !important;
  }
  
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 470px) {
    font-size: 14px;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
}

.main-text-caption, .text-caption {
  position: absolute;
  bottom: -50px; 
  left: 1em;
  // padding: 5px;
  width: 90%;
  color: #FFF;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: 0.4px;
  text-align: center;   
  opacity: 0; 
  transition: bottom 0.5s ease, opacity 0.5s ease; 
  z-index: 10;
  text-align: left;
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 4 * (30px);
  text-overflow: ellipsis;
  pointer-events: none;
  overflow: hidden;
}

.grey-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0.00) 27.6%, rgba(119, 120, 120, 0.42) 51.56%, #404142 100%);
  opacity: 1;
  transition: opacity 0.5s ease;

  @media screen and (min-width: 768px) {
    opacity: 0;
  }
}

.main:hover .grey-wrap, .side-down:hover .grey-wrap, .side-up:hover .grey-wrap {
  opacity: 1;
}


</style>