<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { NewsProps } from "../store";
import { useRouter } from "vue-router";
import MarkdownIt from 'markdown-it';
import arrow from '../assets/arrow-black.svg';

const router = useRouter();
defineProps<{ news: NewsProps[] }>();
const markdown = new MarkdownIt();

const isAtStart = ref(true);
const isAtEnd = ref(false);

const articleWrapper = ref<HTMLElement | null>(null);

const getCaption = (content: string) => {
  return /<p>([\s\S]*)<\/p>/.exec(markdown.render(content))?.[0] || "";
};

const clickButton = (articleId?: number) => {
  const articleUrl = 'new';
  router.push(`/${articleUrl}/${articleId}`);
};

const scroll = (direction: string) => {
  if (articleWrapper.value) {
    const container = articleWrapper.value;
    const scrollAmount = 800; // Adjusted for article width and gap
    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }
};

const updateScroll = () => {
  if (articleWrapper.value) {
    const container = articleWrapper.value;
    isAtStart.value = container.scrollLeft === 0;
    isAtEnd.value = container.scrollLeft + container.clientWidth >= container.scrollWidth;
  }
};

onMounted(() => {
  if (articleWrapper.value) {
    articleWrapper.value.addEventListener('scroll', updateScroll);
    updateScroll(); // Initialize the scroll positions
  }
});

onBeforeUnmount(() => {
  if (articleWrapper.value) {
    articleWrapper.value.removeEventListener('scroll', updateScroll);
  }
});
</script>



<template>
  <div :class="['wrap', { 'has-scroll-left': !isAtStart, 'has-scroll-right': !isAtEnd }]">
    <div class="arrow-prev-ucu" @click="scroll('left')" v-if="!isAtStart">
      <img :src="arrow" alt="Previous">
    </div>
    <div class="article-wrapper" ref="articleWrapper">
      <div class="article" v-for="(article, index) in news" :key="index" @click="clickButton(article.id)">
        <img :src="article.imageSource" alt="Article Image" rel="preload" />
        <div class="contents">
          <div class="title-text">{{ article.date }}</div>
          <div class="main-text-caption" v-html="getCaption(article.content)"></div>
        </div>
      </div>
    </div>
    <div class="arrow-next-ucu" @click="scroll('right')" v-if="!isAtEnd">
      <img :src="arrow" alt="Next">
    </div>
  </div>
</template>


<style scoped lang="scss">
.wrap {
  position: relative;
  width: calc(100% + 40px);
  transform: translateX(-20px);
  overflow: hidden;
  position: relative; /* Ensure .wrap is positioned relative */

}

.wrap.has-scroll-left::before,
.wrap.has-scroll-right::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px; /* Adjust the width as needed */
  pointer-events: none; /* Allow clicks through the shadow */
  z-index: 1; /* Ensure the shadow is above the content */
}

.wrap.has-scroll-left::before {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent);
}

.wrap.has-scroll-right::after {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), transparent);
}

.article-wrapper {
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  overflow-x: auto;
  height: 100%;
  scroll-behavior: smooth;
  // scroll-snap-type: x mandatory;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-left: 20px;
  padding-right: 20px;
}

.article-wrapper::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.article {
  position: relative;
  flex: 0 0 auto;
  width: 380px;
  border: 2px solid #999999;
  border-radius: 17px;
  margin-right: 20px;
  overflow: hidden;
  padding-bottom: 15px;
  scroll-snap-align: start;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add this line */

  &:hover {
    opacity: 1;
    transform: scale(1.02); /* Slightly increase the scale */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Add shadow on hover */
  }

  .article:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    display: block;
  }

  @media screen and (max-width: 700px) {
    width: 290px;
    img {
      height: 150px;
    }
  }


  @media screen and (max-width: 500px) {
    width: 230px;
    img {
      height: 120px;
    }
  }
}

.title-text {
  z-index: 2;
  padding: 12px 12px 0 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 800;
  line-height: 125%;
  letter-spacing: 0.02rem;
  text-align: left;
  transition: all 0.5s ease, opacity 0.5s ease;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

.main-text-caption {
  // bottom: -50px; 
  left: 0.5em;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; 
  letter-spacing: 0.025rem;
  text-align: center;
  padding: 12px;
  padding-top: 0px; 
  padding-bottom: 0;   
  transition: bottom 0.5s ease, opacity 0.5s ease; 
  z-index: 10;
  text-align: left;
  height: 250px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  max-height: 8 * (15px); /* Adjust the value as needed */
  text-overflow: ellipsis;
  overflow: hidden;
  
  @media screen and (max-width: 700px) {
    max-height: 8 * (14px);
    height: 230px;
  }


  @media screen and (max-width: 500px) {
    max-height: 7 * (14px); 
    height: 200px;
    font-size: 12px;
  }
}

.main-text-caption :deep(p) {
  margin-top: 7px !important;
  margin-bottom: 7px;
}

.arrow-prev-ucu,
.arrow-next-ucu {
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  border: 2px solid black;
  transition: all 0.3s ease;
  opacity: 0.9;
  cursor: pointer;
  img {
    transform: scale(1.35);
  }

  &:hover {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}

.arrow-prev-ucu {
  left: 15px;
  transform: rotate(180deg);
}

.arrow-next-ucu {
  right: 15px;
}

@media screen and (max-width: 767px) {
  .arrow-prev-ucu,
  .arrow-next-ucu {
    display: none;
  }
}
</style>
