<script setup lang="ts">
import Header from "./components/Header.vue"

import { computed } from 'vue';
import { usePagesStore } from './store';
import { useRouter } from "vue-router";

const router = useRouter();

const store = usePagesStore()

store.getAbouts()
store.getArticles()
store.getMeeting()
store.getPopularArticles()
store.getArticlesRG()
store.getNewsArticles()

const textColor = computed(() => store.currentPage?.bgColor == "white"? "black" : "white")

</script>

<template>
  <div id="app" :style="{ backgroundColor: store.currentPage.bgColor, color: textColor }">
    <Header 
      :bg-color="store.currentPage.bgColor" 
      :logo-text="store.currentPage.title"
      @change-page="(name) => name == 'RG' ? router.push('/rg') : router.push('/')"
    />
    <router-view v-if="store.loaded" v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <component :is="Component" />
      </Transition>
    </router-view>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap&family=Inter:wght@300;400;500;700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Roboto+Mono:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

#app {
  padding: 0 20px;
  padding-bottom: 27px;
  /* padding-top: 15px; */
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

</style>
