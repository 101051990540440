<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n"
const { t } = useI18n();

const props = defineProps({
  context: Object,
})

const timezones = props.context?.options

const selectedIndex = ref<number | null>(null)

const handleClick = (index: number) => {
  selectedIndex.value = index
  props.context?.node.input(timezones[index])
  console.log(selectedIndex.value)
}


</script>

<template>
  <div class="root">
    <div class="wrapper">
      <div class="select-box">
        <div class="select-title">{{ t('select.selectUtcText') }}</div>
        <div class="select-timezones" >
          <div
            class="select-timezone"
            :class="{
              selected: index == selectedIndex
            }"
            v-for="(timezone, index) in timezones"
            :key="index"
            @click="handleClick(index)"
          >
            {{ timezone }}
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<style>
.root {
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.select-box {
  width: 100%;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #d8dadc;
  border-radius: 10px;
  margin-bottom: 22px;
}

.select-title {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #d8dadc;
  border-top: none;
  border-radius: 10px;
}

.select-timezones {
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  overflow: scroll;
  overflow-x: hidden;
  overscroll-behavior-block: contain;
  justify-content: center;
  align-items: center;
  padding-top: 8px;

}



.select-timezone {
  border: 1px solid #d8dadc;
  border-radius: 10px;
  width: 85%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.select-timezone.selected {
  background-color: #d8dadc;
  color: black;
}
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #5C5C5C; /* Set the color of the thumb */
  border-radius: 3px; /* Round the corners of the thumb */
}


::-webkit-scrollbar-thumb:hover {
  background-color: #5C5C5C; /* Set the color of the thumb on hover */
}

::-webkit-scrollbar-track {
  background-color: black; /* Set the color of the track */
}

</style>