<script lang="ts" setup>
import arrowBack from "../assets/arrow-back.svg"
import { ref } from "vue";
import axios from "axios";
import { FormKit } from "@formkit/vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n"
const { t } = useI18n();

const step = ref(0);
const router = useRouter();
const transitionDirection = ref("");

const redirectToMain = () => {
  step.value++;
  setTimeout(() => {
    router.push('/rg');
  }, 2000);
}

function incrementStep(valid: boolean) {
  transitionDirection.value = "fade-right"
  if(valid) step.value++;
  
};


function decrementStep() {
  transitionDirection.value = "fade-left"
  if (step.value > 0) {
    step.value--;
  } else {
    router.go(-1);
  }
};

const belongs = ref(null);
const support = ref(null);


const submit = (formData: any) => {
  redirectToMain()
  const data = Object.values(formData).reduce((acc: any, group: any) => ({ ...acc, ...group }), {})
  axios.post("/api/circlist", data)
};

function rootClasses(sectionKey: string) {
  return {
    [`${sectionKey}`]: false,
  };
}




</script>


<template>
  <div class="root-sendform">
    <div class="form-wrappers">
      <FormKit 
        id="my-form"
        type="form" 
        @submit="submit" 
        :actions="false"
      >
        <transition :name="transitionDirection">
          <div class="form-class" v-show="step === 0">
            <FormKit type="group" #default="{state:{valid}}">
              <h2 class="form-title"> {{ t("sendForm.step_0.titleText") }} </h2>
              <div class="form-inputs">
                <FormKit
                  label-class="label-title"
                  input-class="form-input"
                  inner-class="lable-inner"
                  name="nickname"
                  validation="required|length:3"
                  :label="t('sendForm.step_0.nicknameText')"
                  :placeholder="t('sendForm.step_0.placeholderNicknameText')"
                  :validation-messages="{
                    required: t('sendForm.error.nicknameRequired'),
                    length: t('sendForm.error.nicknameLength')
                  }"
                />
                <FormKit
                  label-class="label-title"
                  input-class="form-input"
                  inner-class="lable-inner"
                  name="email"
                  validation="required|*email"
                  :label="t('sendForm.step_0.emailText')"
                  :placeholder="t('sendForm.step_0.placeholderEmailText')"
                  :validation-messages="{
                    required: t('sendForm.error.emailRequired'),
                    email: t('sendForm.error.emailType')
                  }"
                />
                <FormKit
                  label-class="label-title"
                  input-class="form-input"
                  inner-class="lable-inner"
                  name="username"
                  placeholder="@durov"
                  validation="required|starts_with:@"
                  :label="t('sendForm.step_0.tgUsernameText')"
                  :validation-messages="{
                    required: t('sendForm.error.usernameRequired'),
                    starts_with: t('sendForm.error.usernameStartsWith')
                  }"
                />
                <FormKit
                  label-class="label-title"
                  input-class="form-input"
                  inner-class="lable-inner"
                  name="country"
                  validation="required"
                  :label="t('sendForm.step_0.countryLocationText')"
                  :placeholder="t('sendForm.step_0.placeholderCountryLocationText')"
                  :validation-messages="{
                    required: t('sendForm.error.countryLocationRequired'),
                  }"
                />
              </div>
              <div class="buttons">
                <span class="button-back" @click="decrementStep">
                  <img :src="arrowBack" width="20" alt="back" />
                </span>
                <span @click="incrementStep(valid)" :class="{'red-button': valid }" class="button-next">
                  {{ t("sendForm.buttonNextText") }}
                </span>
              </div>
            </FormKit>
          </div>
        </transition>
        <transition  :name="transitionDirection">
          <div v-show="step === 1" class="form-class">
            <FormKit type="group" #default="{state:{valid}}">
              <h2 class="form-title"> {{ t("sendForm.detailedText") }} </h2>
              <div>
                <div class="form-inputs">
                  <FormKit
                    v-model="belongs"
                    type="radio"
                    label-class="option-label"
                    name="belongs"
                    validation="required"
                    options-class="options"
                    inner-class="inner"
                    messages-class="formkit-messages"
                    decorator-icon-class="decoratorIcon"
                    :options="[
                      { label: t('sendForm.step_1.buttonYes'), value: true },
                      { label: t('sendForm.step_1.buttonNo'), value: false },
                    ]"
                    :classes="{ fieldset: 'fieldset' }"
                    :config="{ rootClasses }"
                    :label="t('sendForm.step_1.belongsReasonText')"   
                  />
                  <FormKit
                    name="belongsReason"
                    label-class="label-title"
                    input-class="form-input"
                    validation-label="Field"
                    validation="required"
                    :disabled="belongs === null"
                    :placeholder="t('sendForm.placeholderReasonText')"
                    :validation-messages="{
                      required: t('sendForm.error.fieldReasonRequired'),
                    }"
                  />
                  <FormKit
                    v-model="support"
                    type="radio"
                    label-class="option-label"
                    name="support"
                    validation="required"
                    options-class="options-column"
                    inner-class="inner"
                    messages-class="formkit-messages"
                    decorator-icon-class="decoratorIcon"
                    :label="t('sendForm.step_1.supportReasonText')"
                    :options="[
                      { label: t('sendForm.step_1.russianSideText'), value: 'Russia' },
                      { label: t('sendForm.step_1.ukrainianSideText'), value: 'Ukraine' },
                      { label: t('sendForm.step_1.neitherSideText'), value: 'Neither' },
                      { label: t('sendForm.step_1.otherSideText'), value: 'Other' },
                    ]"
                    :classes="{ fieldset: 'fieldset' }"
                    :config="{ rootClasses }"
                  />
                  <FormKit
                    label-class="label-title"
                    input-class="form-input"
                    name="supportReason"
                    validation-label="Field"
                    :disabled="support == null" 
                    validation="required"
                    :placeholder="t('sendForm.placeholderReasonText')"
                    :validation-messages="{
                      required: t('sendForm.error.fieldReasonRequired'),
                    }"
                  />
                </div>
              </div>
              <div class="buttons">
                <span class="button-back" @click="decrementStep">
                  <img :src="arrowBack" width="20" alt="back" />
                </span>
                <span @click="incrementStep(valid)" :class="{'red-button': valid }" class="button-next">
                  {{ t("sendForm.buttonNextText") }}
                </span>
              </div>
            </FormKit>
          </div>
        </transition >
        <transition :name="transitionDirection">
          <div v-show="step === 2" class="form-class">
            <FormKit type="group" id="form-clear" #default="{state:{valid}}">
              <h2 class="form-title"> {{ t('sendForm.detailedText') }} </h2>
              <div class="form-inputs">
                <div class="know-topics">
                  <FormKit
                    type="checkbox"
                    label-class="option-label"
                    help-class="topics-help"
                    name="getKnownTopics"
                    validation="required"
                    options-class="options-column"
                    inner-class="inner"
                    messages-class="formkit-messages"
                    decorator-icon-class="decoratorIcon"
                    :label="t('sendForm.step_2.acquaintedProgrammesText')"
                    :options="[
                      { label: t('sendForm.step_2.imperialismProgrammesText'), value: 'imperializm' },
                      { label: t('sendForm.step_2.opportunismProgrammesText'), value: 'opportunism', },
                    ]"
                    :classes="{ fieldset: 'fieldset' }"
                    :config="{ rootClasses }"
                    :help="t('sendForm.helpText')"
                  />
                </div>
                <div class="want-topics">
                  <FormKit
                    type="checkbox"
                    label-class="option-label"
                    help-class="topics-help"
                    name="wantGetInvolvedTopics"
                    validation="required"
                    options-class="options-column"
                    inner-class="inner"
                    messages-class="formkit-messages"
                    decorator-icon-class="decoratorIcon"
                    :label="t('sendForm.step_2.wantProgrammesText')"
                    :options="[
                      { label: t('sendForm.step_2.imperialismProgrammesText'), value: 'imperializm' },
                      { label: t('sendForm.step_2.opportunismProgrammesText'),value: 'opportunism', },
                    ]"
                    :classes="{ fieldset: 'fieldset' }"
                    :config="{ rootClasses }"
                    :help="t('sendForm.helpText')"
                  />
                </div>
              </div>
              <div class="buttons">
                <span class="button-back" @click="decrementStep">
                  <img :src="arrowBack" width="20" alt="back" />
                </span>
                <span @click="incrementStep(valid)" :class="{'red-button': valid }" class="button-next">
                  {{ t('sendForm.buttonNextText') }}
                </span>
              </div>
            </FormKit>
          </div>
        </transition>
        <transition :name="transitionDirection">
          <div v-show="step === 3" class="form-class">
            <FormKit type="group" #default="{state:{valid}}">
              <h2 class="form-title">{{ t('sendForm.detailedText') }}</h2>
              <FormKit
                type="checkbox"
                label-class="option-label"
                help-class="topics-help"
                name="wantDays"
                validation="required"
                validation-label="Field"
                options-class="options-grid"
                inner-class="inner"
                messages-class="formkit-messages"
                decorator-icon-class="decoratorIcon"
                :label="t('sendForm.step_3.daysOfWeekText')"
                :options="[
                  { label: t('sendForm.step_3.MonText'), value: 'Monday' },
                  { label: t('sendForm.step_3.TueText'), value: 'Tuesday' },
                  { label: t('sendForm.step_3.WedText'), value: 'Wednesday' },
                  { label: t('sendForm.step_3.ThuText'), value: 'Thursday' },
                  { label: t('sendForm.step_3.FriText'), value: 'Friday' },
                  { label: t('sendForm.step_3.SatText'), value: 'Saturday' },
                  { label: t('sendForm.step_3.SunText'), value: 'Sunday' },
                ]"
                :classes="{ fieldset: 'fieldset' }"
                :config="{ rootClasses }"
                :help="t('sendForm.helpText')"
              />
              <FormKit
                type="checkbox"
                label-class="option-label"
                help-class="topics-help"
                name="timeStamps"
                validation="required"
                validation-label="Field"
                options-class="options-grid"
                inner-class="inner"
                messages-class="formkit-messages"
                decorator-icon-class="decoratorIcon"
                :label="t('sendForm.step_3.timeIntervalsText')"
                :options="[
                  { label: '08:00 10:00', value: '08:00 - 10:00'},
                  { label: '10:00 12:00', value: '10:00 - 12:00'},
                  { label: '12:00 14:00', value: '12:00 - 14:00'},
                  { label: '14:00 16:00', value: '14:00 - 16:00'},
                  { label: '16:00 18:00', value: '16:00 - 18:00'},
                  { label: '18:00 20:00', value: '18:00 - 20:00'},
                  { label: '20:00 22:00', value: '20:00 - 22:00'},
                ]"
                :classes="{ fieldset: 'fieldset' }"
                :config="{ rootClasses }"
                :help="t('sendForm.helpText')"
              />
              <FormKit
                type="customselect"
                name="timezone"
                validation="required" 
                validation-label="Field"
                :options="[
                  'UTC-12',
                  'UTC-11',
                  'UTC-10',
                  'UTC-9',
                  'UTC-8',
                  'UTC-7',
                  'UTC-6',
                  'UTC-5',
                  'UTC-4',
                  'UTC-3',
                  'UTC-2',
                  'UTC-1',
                  'UTC+0',
                  'UTC+1',
                  'UTC+2',
                  'UTC+3',
                  'UTC+4',
                  'UTC+5',
                  'UTC+6',
                  'UTC+7',
                  'UTC+8',
                  'UTC+9',
                  'UTC+10',
                  'UTC+11',
                  'UTC+12',
                  'UTC+13',
                  'UTC+14',
                ]"
              />
              <FormKit
                label-class="label-title"
                input-class="form-input"
                inner-class="lable-inner"
                name="canReadWeekTime"
                validation="required"
                messages-class="formkit-messages"
                validation-label="Field"
                :label="t('sendForm.step_3.hoursForReadingText')"
                :placeholder="t('sendForm.placeholderAnswerText')"
                :validation-messages="{
                  required: t('sendForm.error.answerRequired'),
                }"
              />
              <FormKit
                label-class="label-title"
                input-class="form-input"
                inner-class="lable-inner"
                name="canAttendWeekTime"
                validation="required"
                validation-label="Field"
                :label="t('sendForm.step_3.hoursForOnlineText')"
                :placeholder="t('sendForm.placeholderAnswerText')"
                :validation-messages="{
                  required: t('sendForm.error.answerRequired'),
                }"
              />
              <FormKit
                type="textarea"
                label-class="label-title"
                input-class="textarea-input"
                inner-class="lable-inner"
                name="personalProposes"
                validation-label="Field"  
                :label="t('sendForm.step_3.wishesText')"
                :placeholder="t('sendForm.placeholderReasonText')"
              />
              <div class="buttons">
                <span class="button-back" @click="decrementStep">
                  <img :src="arrowBack" width="20" alt="back" />
                </span> 
                <FormKit
                  type="submit"
                  outer-class="send-outer"
                  :input-class="{'send-input': true, 'red-button': valid }"
                  :label="t('sendForm.step_3.buttonSendText')"
                  :disabled="!valid" 
                  @submit="submit"
                />
              </div>
            </FormKit>
          </div>
        </transition> 
        <transition :name="transitionDirection">
          <div v-show="step === 4" class="form-class">
            <div class="success-form">
              <div class="success-wrapper">
                <img src="../assets/checked.svg" alt="success" />
                <div class="success-title">
                  {{ t('sendForm.step_4.successSendText') }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </FormKit>
    </div>
  </div>
</template>


<style lang="scss">

.root-sendform {
  margin: 0 auto;
  display: flex;
  text-align: left;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}
.formkit-messages {
  color: red;
  list-style-type: none;
  margin: 5px 0px 15px -38px !important;
  font-size: 14px;
}

.red-button {
  background: #cc0000;
  // transition-delay: 1s;
  transition: background 1s ease 1s;
}

.form-class {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-items: start;
  align-content: start;
  justify-content: space-between;
  min-height: 70vh;
}

.form-wrappers {
  color: #ffffff;
  background-color: #000000;
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid #5c5c5c;
  border-radius: 12px;
  margin-bottom: 25px;

  @media screen and (min-width: 768px) { 
    width: 500px;
    margin: 0 auto;
  }
}
.form-button {
  display: block;
  padding: 10px;
  color: #e9e7e7;
  border: 1px solid #d8dadc;
  width: 40%;
  height: 20%;
  background-color: #000000;
  font-size: 16px;
  font-family: Inter;
}
.form-inputs {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 40px;
}
.form-input {
  display: block;
  background-color: #000000;
  border: 1px solid #8a8a8a;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 10px;

  width: calc(100% - 35px);
  font-size: 16px;
  &:disabled {
    opacity: 60%;
  }
}
.textarea-input {
  display: block;
  background-color: #000000;
  color: white;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  border: 1px solid #8a8a8a;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 15px;
  width: calc(100% - 35px);
  height: 100px;
  font-size: 17px;
}
.outer {
  width: 100%;
}
.form-inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.lable-inner {
  margin-top: 8px;
}

.button-wraper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-title {
  font-size: 36px;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: extra-bold;
  margin-top: 0;
  
}

.label-title {
  font-size: 14px;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  margin-bottom: 7px;
}

.button-back {
  background-color: #eac500;
  width: 56px;
  height: 56px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 18px;
  cursor: pointer;
}

.button-next {
  border: 2px solid #cc0000;
  border-radius: 10px;
  width: 80%;
  height: 56px;
  margin-left: 14px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 18px;
  cursor: pointer;
  
}

.send-outer {
  width: 100%;
}

.send-input {
  border: 2px solid #cc0000;
  border-radius: 10px;
  width: 80%;
  margin-left: 20%;
  padding: 0 1em;
  height: 56px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
input {
  color: white;
}

input::placeholder {
  color: gray;
}

.options {
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  box-sizing: border-box;
  li {
    box-sizing: border-box;
    width: 47%;
  }
}

.options-column {
  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  box-sizing: border-box;
  margin: 10px 0 0 0;
  li {
    margin-bottom: 15px;
  }
}

.options-grid {
  list-style-type: none;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 0;
  flex-direction: row;
  justify-content: start;
  list-style-type: none;
  box-sizing: border-box;
  flex-wrap: wrap;
  li {
    box-sizing: border-box;
    width: 90%;
    margin: 0 15px 1em 0;
  }
}

.inner,
.decoratorIcon {
  display: none;
}

.option-label {
  box-sizing: border-box;
  display: block;
  border: 1px solid #d8dadc;
  padding: 1em 0;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.7;
}

.option-label-clicked {
  background-color: #d8dadc;
  color: #000000;
}

.fieldset {
  border: none;
  display: relative;
  padding: 0;
  margin: 0;
}

li:has(label[data-checked="true"]) {
  background-color: #d8dadc !important;
  color: #000000;
  border-radius: 10px;
}

legend {
  font-size: 14px;
  line-height: 100%;
}

.topics-help {
  font-size: 14px;
  color: #e3d16c;
  line-height: 100%;
  margin-top: 5px;
}

.want-topics {
  margin-top: 25px;
}

textarea::placeholder {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: 17px;
  line-height: 1;
  color: gray;
}

.success-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.success-form {
  min-height: 70vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10em;
}

.success-title{
  margin-top: 10px;
  font-size: 26px;
  text-align: center;
}

.check-block {
  width: 90%;
  height: 55px;
  background-color: #13c53a;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validation-help {
  color: red;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.fade-right-enter-active, .fade-right-leave-active {
  transition: opacity 1s ease, transform 0.2s ease; 
}

.fade-right-leave-from{
  opacity: 0;
  transition: opacity 0s ease;
}

.fade-right-enter-from, .fade-right-leave-to {
  transform: translateX(5%);
  opacity: 0;
  transition: opacity 0s linear;
}

.fade-left-enter-active, .fade-left-leave-active {
  transition: opacity 1s ease, transform 0.2s ease; 
}

.fade-left-leave-from{
  opacity: 0;
  transition: opacity 0s ease;
}

.fade-left-enter-from, .fade-left-leave-to {
  transform: translateX(-5%);
  opacity: 0;
  transition: opacity 0s linear;
}

</style>