import { createRouter, createWebHistory } from "vue-router"
import Root  from "./components/Root.vue"
import SendForm from "./components/SendForm.vue"
import ArticlesPage from "./components/ArticlePage.vue"
import CoverAbout from "./components/CoverAbout.vue"
import MisPage from "./components/MisPage.vue"
import NewArticle from "./components/NewArticle.vue"

import { usePagesStore } from "./store";

const routes = [
  {
    path: "/",
    component: Root,
    props: { currentPageName: "UCU" }
  },
  {
    path: "/rg",
    component: Root,
    props: { currentPageName: "RG" }
  },
  {
    path: "/form",
    component: SendForm,
  },

  {
    path: "/article/:id",
    component: ArticlesPage,
    meta: { scrollToTop: true }
  },
  {
    path: "/articleRg/:id",
    component: ArticlesPage,
    meta: { scrollToTop: true }
  },
  {
    path: "/about",
    component: CoverAbout,
    meta: { scrollToTop: true }
  },
  {
    path: "/rg/about",
    component: CoverAbout,
    meta: { scrollToTop: true }
  },
  {
    path: "/popular-article/:id",
    component: ArticlesPage,
    meta: { scrollToTop: true }
  },
  {
    path: "/rg/program/:id",
    component: ArticlesPage,
    meta: { scrollToTop: true }
  },
  {
    path: "/new/:id",
    component: NewArticle,
    meta: { scrollToTop: true }
  },
  {
    path: "/mis",
    component: MisPage,
    meta: { scrollToTop: true }
  }
]

export const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior(to, _, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.meta.scrollToTop) {
      return { top: 0}
    }

    return {}
  }
});

router.beforeEach((to) => {
  const store = usePagesStore()
  if (to.path.includes("rg") || to.path == "/form") {
    document.body.style.backgroundColor = 'black'
    store.setPage("RG")
  }
  else {
    document.body.style.backgroundColor = 'white'
    store.setPage("UCU")
  }
})