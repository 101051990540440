import { defineStore } from "pinia";
import axios from "axios";


export type PageProps = {
  title: string;
  bgColor: string;
  about: {
    titleText: string;
    titleCaption?: string;
    aboutText: string;
    imageSource: any;
    logoText: string;
    bgColor: string;
  }
}

export type ArticleProps = {
  titleText: string;
  content: string;
  imageSource: string
  author?: string;
  id?: number;
}

export type NewsProps = {
  id: number;
  date: string;
  content: string;
  imageSource: string;
}

export type MeetingProps = {
  titleText: string;
  titleCaption: string;
  imageSource: string;
  buttonText: string;
}

export type Locales = "ru" | "en" | "uk"

const handleCmsProps = (callback: (locale: Locales, attrs: any, id: number) => void) => ({ data }: any) => {
  for (const item of data.data) {
    callback(item.attributes.locale, item.attributes, item.id)
  }
}


const preloadImage = (src: string) => {
  const img = new Image();
  img.src = src;
}

const getBrowserLang = (): Locales => {
  if(navigator.language.split('-')[0] == 'ru') return 'ru'
  if (navigator.language.split('-')[0] == 'uk') return 'uk'
  return 'en'
}

export const usePagesStore = defineStore("pages", {
  state: () => ({
    pagesData: {
      UCU: {
        en: {
          title: "UCU",
          bgColor: "white",
          about: {
            titleText: "",
            titleCaption: "",
            aboutText: "",
            imageSource: "" as any,
            logoText: "",
            bgColor: "white",
          }
        },
        ru: {
          title: "СКУ",
          bgColor: "white",
          about: {
            titleText: "",
            titleCaption: "",
            aboutText: "",
            imageSource: "" as any,
            logoText: "",
            bgColor: "white",
          }
        },
        uk: {
          title: "СКУ",
          bgColor: "white",
          about: {
            titleText: "",
            titleCaption: "",
            aboutText: "",
            imageSource: "" as any,
            logoText: "",
            bgColor: "white",
          },
        }
      },
      RG: {
        en: {
          title: "RG",
          bgColor: "black",
          about: {
            titleText: "",
            titleCaption: "",
            aboutText: "",
            imageSource: "" as any,
            logoText: "",
            bgColor: "black"
          }
        },
        ru: {
          title: "RG",
          bgColor: "black",
          about: {
            titleText: "",
            titleCaption: "",
            aboutText: "",
            imageSource: "" as any,
            logoText: "",
            bgColor: "black"
          }
        },
        uk: {
          title: "RG",
          bgColor: "black",
          about: {
            titleText: "",
            titleCaption: "",
            aboutText: "",
            imageSource: "" as any,
            logoText: "",
            bgColor: "black"
          }
        }
      }
    },
    locale: getBrowserLang(),
    loaded: false,

    articlesData: {
      en: [] as ArticleProps[],
      ru: [] as ArticleProps[],
      uk: [] as ArticleProps[],
    },
    popularArticlesData: {
      en: [] as ArticleProps[],
      ru: [] as ArticleProps[],
      uk: [] as ArticleProps[],
    },
    newsData: {
      en: [] as NewsProps[],
      ru: [] as NewsProps[],
      uk: [] as NewsProps[],
    },
    rgArticlesData:{
      en: [] as ArticleProps[],
      ru: [] as ArticleProps[],
      uk: [] as ArticleProps[],
    },
    rgProgramsData: {
      en: [] as ArticleProps[],
      ru: [] as ArticleProps[],
      uk: [] as ArticleProps[],
    },
    currentPageName: "UCU",
    meetingPropsData: {
      en: {
        titleText: "",
        titleCaption: "",
        imageSource: "",
        buttonText: ""
      },
      ru: {
        titleText: "",
        titleCaption: "",
        imageSource: "",
        buttonText: ""
      },
      uk: {
        titleText: "",
        titleCaption: "",
        imageSource: "",
        buttonText: ""
      }
    }
  }),

  getters: {
    currentPage: (state) => state.pagesData[state.currentPageName as "UCU" | "RG"][state.locale] || { title: "UCU", bgColor: "white" },
    articles: (state) => state.articlesData[state.locale],
    news: (state) => state.newsData[state.locale],
    popularArticles: (state) => state.popularArticlesData[state.locale],
    rgArticles: (state) => state.rgArticlesData[state.locale],
    rgPrograms: (state) => state.rgProgramsData[state.locale],
    ucuAbout: (state) => state.pagesData.UCU[state.locale]?.about || state.pagesData.UCU.en.about,
    rgAbout: (state) => state.pagesData.RG[state.locale]?.about || state.pagesData.RG?.en?.about,
    meetingProps: (state) => state.meetingPropsData[state.locale],
  },

  actions: {
    setPage(title: string) {
      this.currentPageName = title;
    },
    article(id: number){
      return this.articlesData[this.locale].find(article => article.id === id);
    }, 
    rgArticle(id: number){
      return this.rgArticlesData[this.locale].find(article => article.id === id);
    },
    popularArticle(id: number) {
      return this.popularArticlesData[this.locale].find(article => article.id === id);
    },
    rgProgram(id: number) {
      return this.rgProgramsData[this.locale].find(article => article.id === id);
    },
    new(id: number) {
      return this.newsData[this.locale].find(article => article.id  == id)
    },

    getAbouts() {
      return Promise.all([
        axios.get(`/cms/api/ucu-prop?populate=*&locale=all`)
          .then(handleCmsProps((locale, attrs) => {
            preloadImage(attrs.imageSource.data.attributes.url)
            this.pagesData.UCU[locale].about = {
              titleText: attrs.titleText,
              titleCaption: attrs.titleCaption,
              aboutText: attrs.aboutText,
              imageSource: attrs.imageSource.data.attributes.url,
              logoText: 'UCU',
              bgColor: 'white'
            }
            this.loaded = true
          })),

        axios.get(`/cms/api/rg-prop?populate=*&locale=all`)
          .then(handleCmsProps((locale, attrs) => {
            preloadImage(attrs.imageSource.data.attributes.url);
            this.pagesData.RG[locale].about = {
              titleText: attrs.titleText,
              titleCaption: attrs.titleCaption,
              aboutText: attrs.aboutText,
              imageSource: attrs.imageSource.data.attributes.url,
              logoText: 'RG',
              bgColor: 'black'
            }
          })),
      ])
    },

    getMeeting() {
      return Promise.all([
        axios.get(`/cms/api/meeting-prop?populate=*&locale=all`)
          .then(handleCmsProps((locale, attrs) => {
            preloadImage(attrs.imageSource.data.attributes.url);
            this.meetingPropsData[locale] = {
              titleText: attrs.titleText,
              titleCaption: attrs.titleCaption,
              imageSource: attrs.imageSource.data.attributes.url,
              buttonText: attrs.buttonText
            }
          })),
        
        axios.get(`/cms/api/rg-programs?locale=en&populate=*`)
          .then(handleCmsProps((_, attrs, id) => {
            preloadImage(attrs.imageSource.data.attributes.url);
            
            this.rgProgramsData.en.push({
              titleText: attrs.titleText,
              content: attrs.content, 
              author: attrs.author,
              imageSource: attrs.imageSource?.data?.attributes?.url,
              id: id,
            })
            
            for (const { attributes } of attrs.localizations.data) {
              this.rgProgramsData[attributes.locale as Locales].push({
                titleText: attributes.titleText,
                content: attributes.content, 
                author: attributes.author,
                imageSource: attrs.imageSource?.data?.attributes?.url,
                id: id,
              })
            }
          })
        )
      ])
    },          

    getArticles() {
      return axios.get(`/cms/api/articles?locale=en&populate=*`)
        .then(handleCmsProps((_, attrs, id) => {
          preloadImage(attrs.imageSource.data.attributes.url);
          
          this.articlesData.en.push({
            titleText: attrs.titleText,
            content: attrs.content, 
            author: attrs.author,
            imageSource: attrs.imageSource?.data?.attributes?.url,
            id: id,
          })
          
          for (const { attributes } of attrs.localizations.data) {
            this.articlesData[attributes.locale as Locales].push({
              titleText: attributes.titleText,
              content: attributes.content, 
              author: attributes.author,
              imageSource: attrs.imageSource?.data?.attributes?.url,
              id: id,
            })
          }
        })
      )
    },

    getPopularArticles() {
      return axios.get(`/cms/api/popular-articles-ucus?locale=en&populate=*`)
        .then(handleCmsProps((_, attrs, id) => {
          preloadImage(attrs.imageSource.data.attributes.url);
          
          this.popularArticlesData.en.push({
            titleText: attrs.titleText,
            content: attrs.content, 
            author: attrs.author,
            imageSource: attrs.imageSource?.data?.attributes?.url,
            id: id,
          })
          
          for (const { attributes } of attrs.localizations.data) {
            this.popularArticlesData[attributes.locale as Locales].push({
              titleText: attributes.titleText,
              content: attributes.content, 
              author: attributes.author,
              imageSource: attrs.imageSource?.data?.attributes?.url,
              id: id,
            })
          }
        })
      )
    },
  
    getArticlesRG() {
      return axios.get(`/cms/api/rg-article-props?locale=en&populate=*`)
        .then(handleCmsProps((_, attrs, id) => {
          
          preloadImage(attrs.imageSource.data.attributes.url);
          
          this.rgArticlesData.en.push({
            titleText: attrs.titleText,
            content: attrs.content, 
            author: attrs.author,
            imageSource: attrs.imageSource?.data?.attributes?.url,
            id: id,
          })
          
          for (const { attributes } of attrs.localizations.data) {
            this.rgArticlesData[attributes.locale as Locales].push({
              titleText: attributes.titleText,
              content: attributes.content, 
              author: attributes.author,
              imageSource: attrs.imageSource?.data?.attributes?.url,
              id: id,
            })
          }
        })
      )
    },
    getNewsArticles() {
      return axios.get('/cms/api/news-articles?locale=en&populate=*&sort=date:desc')
        .then(handleCmsProps((_, attrs, id) => {
          preloadImage(attrs.media?.data[0].attributes.url);

          this.newsData.en.push({
            date: new Date(attrs.date).toDateString(),
            content: attrs.content,
            imageSource: attrs.media?.data[0].attributes.url,
            id: id
          })

          for (const { attributes } of attrs.localizations.data) {
            this.newsData[attributes.locale as Locales].push({
              date: attrs.date,
              content: attrs.content,
              imageSource: attrs.media?.data[0].attributes.url,
              id: id
            })
          }
        }))
    }
  }

})