<script setup lang="ts">
import { ref, computed } from 'vue';

interface Document {
  name: string;
  thumbnail: string; // Path to the PNG thumbnail
  labels: string[];
  pdfUrl: string;    // Path to the PDF document
}

const documents = ref<Document[]>([
  {
    name: 'Document 1',
    thumbnail: '/assets/thumbnails/thumbnail1.png',
    labels: ['Finance', 'Report'],
    pdfUrl: '/documents/document1.pdf',
  },
  {
    name: 'Document 2',
    thumbnail: '/assets/thumbnails/thumbnail2.png',
    labels: ['Marketing', 'Strategy'],
    pdfUrl: '/documents/document2.pdf',
  },
  // Add more documents as needed
]);

const searchTerm = ref('');

const filteredDocuments = computed(() => {
  if (!searchTerm.value) {
    return documents.value;
  }
  const term = searchTerm.value.toLowerCase();
  return documents.value.filter(doc => {
    return (
      doc.name.toLowerCase().includes(term) ||
      doc.labels.some(label => label.toLowerCase().includes(term))
    );
  });
});
</script>

<template>
    <h1>Mis</h1>
    <div class="document-database">
      <div class="search-bar">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Search by name or label"
        />
      </div>
  
      <div class="document-list">
        <div
          class="document-item"
          v-for="doc in filteredDocuments"
          :key="doc.name"
        >
          <img :src="doc.thumbnail" :alt="doc.name" class="thumbnail" />
          <div class="document-info">
            <h3 class="document-name">{{ doc.name }}</h3>
            <div class="document-labels">
              <span class="label" v-for="label in doc.labels" :key="label">
                {{ label }}
              </span>
            </div>
            <a :href="doc.pdfUrl" target="_blank" class="view-pdf">View PDF</a>
          </div>
        </div>
      </div>
    </div>

  </template>
  

<style lang="scss">
  .document-database {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  
    .search-bar {
      margin-bottom: 20px;
  
      input {
        color: black;
        width: 100%;
        padding: 10px;
        font-size: 16px;
      }
    }
  
    .document-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
  
      .document-item {
        width: calc(50% - 20px);
        background: #f9f9f9;
        padding: 15px;
        border: 1px solid #ddd;
        display: flex;
        align-items: flex-start;
        transition: box-shadow 0.3s ease;
  
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
  
        .thumbnail {
          width: 80px;
          height: 100px;
          object-fit: cover;
          margin-right: 15px;
        }
  
        .document-info {
          flex: 1;
  
          .document-name {
            margin: 0 0 10px;
            font-size: 18px;
          }
  
          .document-labels {
            margin-bottom: 10px;
  
            .label {
              display: inline-block;
              background: #e0e0e0;
              padding: 4px 8px;
              margin-right: 5px;
              margin-bottom: 5px;
              border-radius: 4px;
              font-size: 12px;
            }
          }
  
          .view-pdf {
            display: inline-block;
            color: #007bff;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  </style>
  