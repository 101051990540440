<script lang="ts" setup>
import { computed, ref } from "vue";
import { usePagesStore, ArticleProps } from '../store';
import { useRoute } from "vue-router";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import MarkdownIt from 'markdown-it'
import { MqResponsive } from 'vue3-mq'
import ArticlesSide from './ArticlesSide.vue';

const routes = useRoute()
const router = useRouter();
const store = usePagesStore()
const copied = ref<boolean>(false);

const coverHeight = ref<any>(0)

const id = ref(+routes.params.id);


onBeforeRouteUpdate((to, _, next) => {
  id.value = +to.params.id
  next()
})

// const id = +routes.params.id

const markdown = new MarkdownIt()
const url = window.location.href;

const props = defineProps<{
  articlesData?: ArticleProps,
  sideArticlesRow?: boolean
}>()


const data = computed(() => {
  if(props.articlesData) {
    return props.articlesData
  }
  if (routes.path.split("/")[1] == "article"){  
    return store.article(id.value)
  }
  if (routes.path.split("/")[1] == 'articleRg'){
    return store.rgArticle(id.value)   
  }

  if (routes.path.split("/")[1] == 'popular-article') {
    return store.popularArticle(id.value)
  }
  if (routes.path.split("/")[2] == 'program') {
    return store.rgProgram(id.value)
  }
})

const shareText = computed(() => copied.value ? 'Copied' : 'Share');

const copyToClipboard = async() => {
  const currentURL = window.location.href;

  try {
    await navigator.clipboard.writeText(currentURL);
    copied.value = true;
    setTimeout(() => {
      copied.value = false;
    }, 1500);
  } catch (error) {
    console.error('Failed to copy to clipboard:', error);
  }
} 



</script>


<template>
<Transition name="fade" mode="out-in">
<div :class="{'root': !sideArticlesRow, 'root-row': sideArticlesRow}" :style="{'flex-direction': sideArticlesRow ? 'row' : 'column' }" v-if="data" :key="id">
  <div :class="{'article-wrapper': !sideArticlesRow, 'article-wrapper-row': sideArticlesRow}"  >
    <div :class="{'article-img': !sideArticlesRow, 'article-img-row': sideArticlesRow}">
      <img :src="data.imageSource" alt="" />
    </div>
    <div class="title-box">
      <div class="title-border">
        <div class="title" >
          <p>
      {{ data.titleText }}      
          </p>  
        </div>
        <div class="author" v-if="data.author">
          <p>{{ data.author }}</p>
        </div>
      </div>
    </div>
    <div class="article-text">
      <p :lang="store.locale" v-html="markdown.render(data.content)" />  
    </div>

    <div class="datetime-block" :class="{'black': store.currentPageName == 'RG'}">
      <div>
        <span>11.05.2023</span>
      </div>
    </div>

    <div class="article-buttons">
      <div class="buttons-border" @click="router.back()">
        <div class="return-button" :class="{'border   ': store.currentPageName == 'RG'}" > <!-- :style="{ 'border-color': bgColor ? 'white' : 'black' }" -->
          <!-- <img :src="arrow" alt=""> -->
          <svg class="arrow" :class="{'fill': store.currentPageName == 'RG'}" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        </div>
        <div div class="return-text" >RETURN</div>
      </div>
      <div class="buttons-border" @click="copyToClipboard"  :class="{ 'copied': copied }">
        <div class="share-button" :class="{'border': store.currentPageName == 'RG'}" > <!-- :style="{ 'border-color': bgColor ? 'white' : 'black' }" -->
          <!-- <img :src="share" alt=""> -->
          <!-- <svg class="share" :class="{'fill': store.currentPageName == 'RG'}" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 336 512"><path d="M352 224c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96c0 4 .2 8 .7 11.9l-94.1 47C145.4 170.2 121.9 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.9 0 49.4-10.2 66.6-26.9l94.1 47c-.5 3.9-.7 7.8-.7 11.9c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-25.9 0-49.4 10.2-66.6 26.9l-94.1-47c.5-3.9 .7-7.8 .7-11.9s-.2-8-.7-11.9l94.1-47C302.6 213.8 326.1 224 352 224z"/></svg> -->
          <span>
            <svg v-if="!copied" class="share" :class="{'fill': store.currentPageName == 'RG'}" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24"><path d="M 18 2 C 16.35499 2 15 3.3549904 15 5 C 15 5.1909529 15.021791 5.3771224 15.056641 5.5585938 L 7.921875 9.7207031 C 7.3985399 9.2778539 6.7320771 9 6 9 C 4.3549904 9 3 10.35499 3 12 C 3 13.64501 4.3549904 15 6 15 C 6.7320771 15 7.3985399 14.722146 7.921875 14.279297 L 15.056641 18.439453 C 15.021555 18.621514 15 18.808386 15 19 C 15 20.64501 16.35499 22 18 22 C 19.64501 22 21 20.64501 21 19 C 21 17.35499 19.64501 16 18 16 C 17.26748 16 16.601593 16.279328 16.078125 16.722656 L 8.9433594 12.558594 C 8.9782095 12.377122 9 12.190953 9 12 C 9 11.809047 8.9782095 11.622878 8.9433594 11.441406 L 16.078125 7.2792969 C 16.60146 7.7221461 17.267923 8 18 8 C 19.64501 8 21 6.6450096 21 5 C 21 3.3549904 19.64501 2 18 2 z M 18 4 C 18.564129 4 19 4.4358706 19 5 C 19 5.5641294 18.564129 6 18 6 C 17.435871 6 17 5.5641294 17 5 C 17 4.4358706 17.435871 4 18 4 z M 6 11 C 6.5641294 11 7 11.435871 7 12 C 7 12.564129 6.5641294 13 6 13 C 5.4358706 13 5 12.564129 5 12 C 5 11.435871 5.4358706 11 6 11 z M 18 18 C 18.564129 18 19 18.435871 19 19 C 19 19.564129 18.564129 20 18 20 C 17.435871 20 17 19.564129 17 19 C 17 18.435871 17.435871 18 18 18 z"></path></svg>
            <svg v-if="copied" class="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
          </span>
        </div>
        <navigator-share
          v-bind:on-error="() => null"
          v-bind:on-success="() => null"
          v-bind:url="url"
          v-bind:title="data.titleText"
        >
          <div class="share-text">
            {{ shareText }}
            <!-- <i v-if="copied" class="fa-solid fa-check fa-fade" style="color: #27930d;"></i> -->
            <!-- <span v-if="copied">
              <svg class="check" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
            </span>           -->
          </div>
        </navigator-share> 
      </div>
    </div>
  </div>

  <mq-responsive target="md+" :class="{'side-articles': !sideArticlesRow, 'side-articles-row': sideArticlesRow}">
    <ArticlesSide
      :display-height="coverHeight"
      :row-display="sideArticlesRow"
      :article1="store.popularArticles[0]"
      :article2="store.popularArticles[1]"
      :article3="store.popularArticles[2]"
    />
  </mq-responsive>

</div>
</Transition>
</template>

<style scoped lang="scss">
// img{
//   color: #fff;
// }


.arrow{
  fill: black;
  transform: rotate(180deg);
}
  .fill{
    fill: white;
  }

.share{
  scale: 1.5;
  font-weight: 100 !important;
  padding-top: 1px;
  padding-right: 1px;
}


.root-row {
  margin: 0 auto;
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: center;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  width: 100%;
  @media screen and (min-width: 1250px) {
    padding: 0 160px;    
  }
  @media screen and (max-width: 1250px) and (min-width: 950px) {
    padding: 0 40px;
  }
  @media screen and (min-width: 768px) and (max-width: 950px) {
    padding: 0 160px;    
  }

}

.root {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  align-items: center;
  justify-content: center;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  width: 100%;
  @media screen and (min-width: 1250px) {
    padding: 0 80px;    
  }
  @media screen and (max-width: 1250px) and (min-width: 950px) {
    padding: 0 40px;
  }
  @media screen and (min-width: 768px) and (max-width: 950px) {
    padding: 0 80px;    
  }

}

.copied{
  color: green;

}
.check{
  fill:#27930d;
  animation: fadeOut 0.6s infinite alternate;
  scale: 1.3;
  padding-top: 1px;
  padding-left: 1px;
}

@keyframes fadeOut {
      0% {
          opacity: 1;
      }
      100% {
          opacity: 0;
      }
  }


.article-wrapper-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

.article-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}
.title-box{
  position: relative;
  margin-top: 0;
}

.article-img-row{
  img {
    border-radius: 17px;
    width: 100%;
    height: 253px;
    object-fit: cover;
    display: block;

    @media screen and (min-width: 1010px) {
      height: 250px;
    }

    @media screen and (min-width: 1200px) {
      height: 400px;
    }

    @media screen and (min-width: 1586px) {
      height: 577px;
    }
  }
}

.article-img {
  img {
    border-radius: 17px;
    width: 100%;
    height: 400px;
    object-fit: cover;
    display: block;

    @media screen and (min-width: 1010px) {
      height: 400px;
    }

    @media screen and (min-width: 1200px) {
      height: 500px;
    }

    @media screen and (min-width: 1586px) {
      height: 577px;
    }
  }
}

.title-border{
  margin-top: 25px;
  border: 3px solid #CC0000;
  border-radius: 12px;
}

.title{
  margin: 50px 30px;
  p{
    text-align: center;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 124.523%; /* 49.809px */
    letter-spacing: 1px;
    margin: 0;

    @media screen and (max-width: 536px) {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 536px) {
    margin: 20px 15px;
  }
}
.author {
  position: absolute;
  background: #fff;
  width: 50%;
  top: 99%;
  left: 50%;  
  transform: translate(-50%, -50%);
  text-align: center;
}

.article-text {
  font-family: "Roboto", Arial, sans-serif;
  line-height: 150%;
  font-size: 20px;
  hyphens: auto;
  hyphenate-character: '-';
  -webkit-hyphenate-character: "-";
  hyphenate-character: "-";  
  z-index: 10;
  p {
    margin-bottom: 34px;
  }
  :deep(h1, h2, h3, h4, h5, h6) {
  // text-align: center;
    line-height: 110%;
  }
}

.article-buttons{
  display: flex;
  justify-content: space-between;
  font-family: "Inter";
  font-weight: 500;

  @media screen and (min-width: 768px) {
    justify-content: space-around;
    gap: 30px;
  }
}

.buttons-border{
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 2px solid #BFBFBF;
  border-radius: 35px;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    width: 200px;
  }

  &:nth-child(2){
    flex-direction: row-reverse;
  }
  &:hover .return-button {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    transform: translateX(-6px);
    opacity: 1;
  }
  &:hover .share-button{
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    transform: translateX(6px);
    opacity: 1;
  }
}


.return-button, .share-button  {
  margin: 10px 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0px 8px 15px -5px rgba(0,0,0,0.3);
  border: 2px solid black;
  transition: all 0.3s ease;
  
  // img{
  //     transform: rotate(180deg);
  // }
}

.border{
  border: 2px solid white;
}

.share-button img{
  transform: rotate(0deg);
}

::v-deep blockquote{
  font-style: italic;
  border-left: 6px solid rgb(234, 234, 239);
  padding-left: 26px;
  margin-left: 0;
}

.share-text {
  text-transform: uppercase;
  position: relative;
  &>span{
    color: green;
    position: absolute;
    left: 120%;
    bottom: 0px;
    scale: 1.3;
  }
}

.side-articles-row {
  margin-left: 45px;
  width: 40%;
  @media screen and (max-width: 1550px) {
    width: 30%
  }
  @media screen and (max-width: 950px) {
    display: none;
  }
  .side-article {
    margin-bottom: 130px;
    img {
      width: 500px;
    }
  }
}


.side-articles {
  width: 85%;
  
  @media screen and (max-width: 950px) {
   width: 100%;
  }
  // .side-article {
  //   margin-bottom: 130px;
  //   img {
  //     width: 500px;
  //   }
  // }
}

.datetime-block {
  width: 100%;
  border: 1.5px solid #CC0000;
  position: relative;
  margin-bottom: 34px;

  div {
    position: absolute;
    top: -10px;
    display: flex;
    width: 100%;
    justify-content: center;
    span {
      background-color: white;
      padding: 4px 35px;
    }
  }
}

.datetime-block.black {
  div {
    span {
      background-color: black;
    }
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s ease;
}

</style>