import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import './style.css'
import App from './App.vue'
import { router } from './router'

import { plugin, defaultConfig, createInput } from '@formkit/vue'
import Select from './components/Select.vue'
import Vue3TouchEvents from "vue3-touch-events";
import messages from './locales'
import { Vue3Mq } from 'vue3-mq' 
import { Locales } from './store'

const getBrowserLang = (): Locales => {
  if(navigator.language.split('-')[0] == 'ru') return 'ru'
  if (navigator.language.split('-')[0] == 'uk') return 'uk'
  return 'en'
}


const pinia = createPinia()
const i18n = createI18n({
  legacy: false,
  locale: getBrowserLang(),
  fallBackLocale: 'en',
  messages
})

createApp(App)
  .use(pinia)
  .use(i18n)
  .use( 
    plugin,
    defaultConfig({
      inputs: {
        customselect: createInput(Select, {
          props: ['options'],
        }),
      }
    }) 
  ) 
  .use(router)
  .use(Vue3TouchEvents)
  .use(Vue3Mq)
  .mount('#app')
