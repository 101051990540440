<script lang="ts" setup >
import { computed, ref } from "vue";
import { PageProps } from "../store"
import MarkdownIt from 'markdown-it'
import arrow from '../assets/arrow-black.svg'
import { useI18n } from "vue-i18n"
import { usePagesStore } from '../store';

const props = defineProps<{
  currentComponent: string;
  buttonState: string;
  aboutProps: PageProps["about"];
  disabled?: boolean;
  icon: string;
}>()

const store = usePagesStore()


  const { t } = useI18n()

  const scrollEl = ref<any>(null);

  const emit = defineEmits<{
    (e: "nextPage"): void;
    (e: "nextComponent", direction: string): void;
    (e: "openAbout"): void;
  }>()

  const isAboutOpened = ref<boolean>(false)
  const visibleButton = ref<boolean>(false)

  const textColor = computed(() => props.aboutProps.bgColor == "white"? "black" : "white")

  const clickButton = () => {
    if (props.disabled) return
    if (props.buttonState === 'expand') {
      emit("openAbout")
      if (window.innerWidth < 768) {
        scrollEl.value.scrollIntoView({ behavior: "smooth" })
        isAboutOpened.value = !isAboutOpened.value
      }
    }
    else emit("nextPage")
  }

  const buttonText = computed(() => {
    if(isAboutOpened.value) {
        return t("cover.buttonClose")
    } else {
      if(props.buttonState == "expand") {
        return t("cover.buttonRead")
      } else {
        return t("cover.buttonAbout")
      }
    }
  })

  const markdown = new MarkdownIt()

  const handleSwipe = (ev: string) => {
    emit("nextComponent", ev)
  }

  const press = () => {
    visibleButton.value = true
  }

  const release = () => {
    visibleButton.value = false
  }
</script>


<template>
  <div class="cover"
    ref="scrollEl"
    :class="{ visible: isAboutOpened, 'cover-black': props.aboutProps.bgColor == 'black' }"
    :style="{color: textColor}"
    v-touch:swipe.right="handleSwipe"
    v-touch:swipe.left="handleSwipe"
    v-touch:drag.once="press"
    v-touch:release="release"
  >
    <div class="title">
      <div class="coverTitle">
        <div>
          <span>{{ aboutProps.titleText }}</span>
          <div class="titleCaption" v-if="!isAboutOpened">
            {{  aboutProps.titleCaption  }}
          </div>
        </div>
        <div class="img">
          <img :src="icon">
        </div>
      </div>
    </div>
   
      <div class="coverAboutUs">
        <p v-if="isAboutOpened" v-html="markdown.render(aboutProps.aboutText)" :lang="store.locale"></p>
      </div>
      <div class="coverContent">
        <div class="buttonImage">
          <div class="coverImg" >
              <img :src="aboutProps.imageSource" alt="" rel="preload" fetchpriority="high"/>
          </div>
          <div class="coverButton">
              <div class="coverBtn" :class="{'disabled': disabled}" @click="clickButton">
                  <span class="btnText">
                      {{ buttonText }}
                  </span>
              </div>
          </div>
        </div>
        <!-- <MqResponsive target="md+">
          <div class="coverText">
            <span>Nam viverra magna ligula, at egestas urna feugiat in. Sed non ex eros. Sed lacinia risus in rutrum tristique. Nunc dui nisl, sagittis at facilisis sed, tempus in est. Quisque dignissim eu dolor vel aliquet. Quisque sed luctus ante, sed pretium mauris.

Nam viverra magna ligula, at egestas urna feugiat in. Sed non ex eros. Sed lacinia risus in rutrum tristique. Nunc dui nisl, sagittis at facilisis sed, </span>
          </div>
        </MqResponsive> -->
      </div>
    
      <div
        v-if="!isAboutOpened"
        :class="{
          'arrow-next-ucu': currentComponent == 'UCU',
          'arrow-next-rg': currentComponent == 'RG',
          'visible': visibleButton
        }"
        @click="emit('nextComponent', currentComponent == 'UCU'? 'left' : 'right')"
      >
        <img :src="arrow">
      </div>
  </div>
</template>

<style lang="scss" scoped>


.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.cover {
  .title {
    height: 200px;
    @media screen and (max-width: 493px) {
      height: 150px;
    }
  }
    background: #FCFBFB;
    position: relative;
    border: 2px solid #999999;
    border-radius: 12px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 615px;
    @media screen and (min-width: 500px) {
      max-height: 800px;
    }
    transition: max-height 0.3s ease;

  @media  screen and (min-width: 768px) {
    border: 4px solid #cc0000;
  }
  @media screen and (min-width: 1600px) {
    padding: 0 40px;
  }
}

.cover.cover-black {
  background-color: black;
  color: white;
}


.cover.visible {
  border: 2px solid #cc0000;
  max-height: 5939px;
}

.coverTitle {
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 124.523%; /* 59.771px */
  letter-spacing: 1.2px;

  @media screen and (min-width: 1600px) {
    font-size: 46px;
  }

  @media screen and (max-width: 493px) {
    font-size: 30px; 
  }
  @media screen and (max-width: 400px) {
    font-size: 26px; 
  }
  text-align: start;
  display: flex;
  align-items: stretch;
  justify-items: start;
  justify-content: start;
  margin-top: 25px;
  text-align: left;
  width: 100%;
  .img {
      display: flex;      
      justify-content: end;
      margin-left: 5px;
      width: 100%;
    img {
      width: 90%;
      height: 83%;    
      @media screen and (min-width: 500px) {
        max-height: 162px;
      }
      @media screen and (max-width: 350px) {
        display: none;
      }
    }
  }
}

.titleCaption{
  font-family: Roboto, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  line-height: 120%; /* 30px */
  letter-spacing: 0.4px;
  width: 100%;
  margin-bottom: 2px; 
  margin-top: 20spx;
  text-align: start;
  overflow: hidden;
  @media screen and (max-width: 494px) {
    font-size: 15px;
    margin-top: 5px;
  }
}
.coverAboutUs {
  font-family: Roboto, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2%;
  line-height: 1.4em;
  text-align: start;
  opacity: 1;
  transition: opacity 0.2s ease;

}
.coverAboutUs > p{
  hyphens: auto;
  hyphenate-character: '-';
  -webkit-hyphenate-character: "-";
  hyphenate-character: "-";  

}

::v-deep blockquote{
  font-style: italic;
  border-left: 6px solid rgb(234, 234, 239);
  padding-left: 26px;
  margin-left: 0;
}

.fade-enter-from {
  opacity: 0;
}

.coverImg {
  margin-top: 1em;
  img {
    width: 100%;
    max-width: 100%; 
    height: auto;
    margin-bottom: 40px;
  }
}

.cover.cover-black .coverBtn {
  border: 3px solid #5C5C5C;
}

.coverBtn {
  border: 3px solid #000;
  border-radius: 34px;
  width: 99%;
  margin: 0 auto;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    cursor: pointer;
    transform: translateY(-3px); /* Move the button 3 pixels up */
    box-shadow: 0 2px 4px rgba(209, 44, 44, 0.712); /* Apply a box shadow */
  }
  &:active {
    box-shadow: none;
  }

  &.disabled:hover {
    transform: none;
  }

  @media screen and (max-width: 493px) {
    padding: 14px 0;
  }
}

.coverBtn.disabled:hover {
  transform: none;
}

.btnText {
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 124.523%;
  margin: 0;
  @media screen and (max-width: 493px) {
    font-size: 24px;
  }
}

.arrow-next-ucu {
  position: absolute;
  top: 40%;
  right: -20px;
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  border: 2px solid black;
  transition: all 0.3s ease;
  opacity: .1;
  &:hover {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
    transform: translateY(-2px);
    opacity: 1;
  }
  @media screen and (min-width: 767px) {
    display: none;
  }
}

.arrow-next-rg {
  position: absolute;
  top: 40%;
  left: -20px;
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  border: 2px solid black;
  transition: all 0.3s ease;
  transform: rotate(180deg);
  opacity: .1;
  &:hover {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
    transform: translateY(-2px) rotate(180deg);
    opacity: 1;
  }
  @media screen and (min-width: 767px) {
    display: none;
  }
}
.arrow-next-rg.visible {
  transform: rotate(180deg);
  opacity: 1;
}

.arrow-next-ucu.visible {
  opacity: 1;
}

</style>

