<script lang="ts" setup>
import { useRouter } from 'vue-router';
import MarkdownIt from 'markdown-it'
import { usePagesStore } from '../store';

defineProps<{
  rowDisplay?: boolean
}>()

const store = usePagesStore();
const router = useRouter();

const markdown = new MarkdownIt()
const article1 = store.popularArticle(1)
const article2 = store.popularArticle(2)
const article3 = store.popularArticle(3)


const getCaption = (content: string) => {
  const modifiedContent = markdown.render(content).replace(/<[^>]*>/gm, '');
  return modifiedContent
}
</script>

<template>
  <div class="root">
    <div v-if="store.currentPageName == 'UCU'" :class="{'wrapper': !rowDisplay, 'wrapper-row': rowDisplay}">
      <div v-if="article1" class="main" @click="router.push(`/popular-article/${article1.id}`)">
        <div class="text">{{ article1.titleText  }}</div>
        <div class="grey-wrap"></div>
        <img class="img" :src="article1.imageSource" alt="">
        <div class="text-caption">{{ getCaption(article1.content) }}</div>
      </div>
      <div v-if="article2" class="main" @click="router.push(`/popular-article/${article2.id}`)">
        <div class="text">{{ article2.titleText }}</div>
        <div class="grey-wrap"></div>
        <div class="text-caption">{{ getCaption(article2.content) }}</div>
        <img class="img" :src="article2.imageSource" alt="">
      </div>
      <div v-if="article3" class="main" @click="router.push(`/popular-article/${article3.id}`)">
        <div class="text">{{ article3.titleText }}</div>
        <div class="grey-wrap"></div>
        <div class="text-caption">{{ getCaption(article3.content) }}</div>
        <img class="img" :src="article3.imageSource" alt="">
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.wrapper-row {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.root {
  padding: 0 !important;
}

.main {
  height: 308px;
  margin-bottom: 60px;
  overflow: hidden;
  position: relative;
  border-radius: 0.5em;
  width: 100%;
  cursor: pointer;
  @media screen and (max-width: 1550px) {
    height: 200px;
  }
}

img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: opacity 0.5s ease;
  border-radius: 0.9375rem;
}

.text {
  font-size: 16px !important;
  @media screen and (min-width: 768px) {
    font-size: 24px !important;
  }
}


@media screen and (min-width: 768px) {
  .main:hover, .side-up:hover, .side-down:hover {
    .text {
      bottom: 6.5em;
      padding-bottom: 2px;
    }
    .text {
      bottom: 6.5em;
      padding-bottom: 2px;
    }

    .text-caption {
      bottom: 20px;
      opacity: 1;
    }

    .text-caption {
      bottom: 20px;
      opacity: 1;
    }
  } 
}

.text {
  padding-bottom: 20px;
  z-index: 2;
  position: absolute;
  bottom: 0em;
  left: 1em;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  letter-spacing: 0.02rem;
  text-align: left;
  transition: bottom 0.5s ease, opacity 0.5s ease;
  
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 470px) {
    font-size: 14px;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
}

.text-caption {
  position: absolute;
  bottom: -50px; 
  left: 1em;
  padding: 5px;
  width: 90%;
  color: #FFF;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: 0.4px;
  text-align: center;   
  opacity: 0; 
  transition: bottom 0.5s ease, opacity 0.5s ease; 
  z-index: 10;
  text-align: left;
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 4 * (30px);
  text-overflow: ellipsis;
  pointer-events: none;
  overflow: hidden;
}

.grey-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0.00) 27.6%, rgba(119, 120, 120, 0.42) 51.56%, #404142 100%);
  opacity: 1;
  transition: opacity 0.5s ease;

  @media screen and (min-width: 768px) {
    opacity: 0;
  }
}

.main:hover .grey-wrap, .side-down:hover .grey-wrap, .side-up:hover .grey-wrap {
  opacity: 1;
}


</style>