<script lang="ts" setup>
import ArticlePage from './ArticlePage.vue';
import { usePagesStore } from '../store';
import { useRoute } from 'vue-router';
import { computed } from 'vue';


  const store = usePagesStore();
  const route = useRoute();

  const about = computed(() => route.path.split("/")[1] == "about" ? store.ucuAbout : store.rgAbout)

  const articlesData = computed(() => ({
    titleText: about.value.titleText,
    content: about.value.aboutText,
    imageSource: about.value.imageSource
  }))

</script>

<template>
  <ArticlePage 
    :articles-data="articlesData"
    :side-articles-row="true"
  >
  </ArticlePage>
</template>
